import { put } from 'redux-saga/effects'
import * as action_type from '../../../../Store/Constant';
import { AUTH_HEADERS, API_URL, AUTH_MULTYPART_HEADERS } from '../../../../Axios/axiosData'
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

export function* orgResourceSaga({ payload }) {

    yield axios.post(API_URL + "resources/resources-profile/", payload, AUTH_MULTYPART_HEADERS())
        .then(resp => {

            if (resp.data.status < 205) {
                let user_data = JSON.parse(localStorage.getItem("user_data"))
                user_data.profile = resp.data.profile
                localStorage.setItem("user_data", JSON.stringify(user_data))
                window.location.reload('.')
            }
            else {
                toast.error(`Failed to submit form with status code ${resp.data.status}`)
            }
        }).catch((error) => {

            if (error?.response?.data?.company_website) {
                toast.error(`${"Website"} : ${error?.response?.data?.company_website[0]}`)
            }

            if (error?.response?.data?.company_name) {
                toast.error(`${error?.response?.data?.company_name[0]}`)
            }

            if (error?.response?.data?.cv) {
                toast.error("CV - Only Pdf and MsWord files are allowed")
            }

            setTimeout(() => {
                window.location.reload('.')
            }, 5000);

        })

    //yield put({ type: action_type.SET_LOADING, payload:false })
}

export function* resInitialProfileData(payload) {

    let data = { response: false, backend_data: null }
    yield put({ type: action_type.SET_LOADING, payload: true })

    yield axios.post(API_URL + "accounts/user-profile/", payload?.payload, AUTH_MULTYPART_HEADERS())
        .then(resp => {

            if (resp?.status == 200) {
                let user_data = JSON.parse(localStorage.getItem("user_data"))
                user_data = resp.data
                localStorage.setItem("user_data", JSON.stringify(user_data))
            }
            else {
                toast.error(`Net Work Error`)
            }

            data.response = true
            data.backend_data = resp.data

        }).catch((error) => {
            console.log(error, "error")
        })

    yield put({ type: action_type.SET_RES_INITIAL_PROFILE, data })
    yield put({ type: action_type.SET_LOADING, payload: false })

}