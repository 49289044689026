import { put } from "redux-saga/effects";
import * as action_type from "../../../../Store/Constant";
import {
  AUTH_MULTYPART_HEADERS,
  API_URL,
  AUTH_HEADERS,
  LIMIT_DATA,
  HEADERS,
} from "../../../../Axios/axiosData";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

export function* getJobPostListData(payload) {
  let data = { response: false, backend_data: null };
  yield put({ type: action_type.SET_LOADING, payload: true });

  let url;

  if (payload?.payload) {
    url = API_URL + `job/job-post/?limit=${LIMIT_DATA}&${payload?.payload}`;
  } else {
    url = API_URL + `job/job-post/?limit=500`;
  }

  yield axios
    .get(url, AUTH_HEADERS())
    .then((resp) => {
      data.response = true;
      data.backend_data = resp.data;
    })
    .catch((error) => {
      console.log(error, "error");
    });

  yield put({ type: action_type.SET_JOB_POST_LIST, data });
  yield put({ type: action_type.SET_LOADING, payload: false });
}

export function* deleteJobPostData(payload) {
  let data = { response: false, backend_data: null };
  yield put({ type: action_type.SET_LOADING, payload: true });

  yield axios
    .delete(API_URL + `job/job-post/${payload?.payload}/`, AUTH_HEADERS())
    .then((resp) => {
      data.response = true;
      data.backend_data = resp.data;

      toast.success(`${resp?.data?.message}`);
    })
    .catch((error) => {
      console.log(error, "error");
    });

  yield axios
    .get(API_URL + `job/job-post/?limit=${LIMIT_DATA}`, AUTH_HEADERS())
    .then((resp) => {
      data.response = true;
      data.backend_data = resp.data;
    })
    .catch((error) => {
      console.log(error, "error");
    });

  yield put({ type: action_type.SET_JOB_POST_LIST, data });
  yield put({ type: action_type.SET_LOADING, payload: false });
}

export function* addNewJobPost(value) {
  let data = { response: false, backend_data: null };
  yield put({ type: action_type.SET_LOADING, payload: true });

  yield axios
    .post(API_URL + `job/job-post/`, value?.payload, AUTH_HEADERS())
    .then((resp) => {
      data.response = true;
      data.backend_data = resp.data;

      if (value?.data) {
        value?.data();
      }

      toast.success("Job Post Created Successfully");
    })
    .catch((error) => {
      if (value?.data) {
        value?.data();
      }
      if (error?.response?.data?.title) {
        toast.error(`${"Title"} :${error?.response?.data?.title[0]}`);
      }

      if (error?.response?.data?.company_city) {
        toast.error(
          `${"Company_city"} :${error?.response?.data?.company_city[0]}`
        );
      }

      if (error?.response?.data?.company_name) {
        toast.error(
          `${"Company_name"} :${error?.response?.data?.company_name[0]}`
        );
      }

      if (error?.response?.data?.company_website) {
        toast.error(
          `${"Company_website"} :${error?.response?.data?.company_website[0]}`
        );
      }

      if (error?.response?.data?.detail) {
        toast.error(`${error?.response?.data?.detail}`);
      }

      if (error?.response?.data?.job_visibility) {
        toast.error("Please Select Job visibility choice");
      }

      if (error?.response?.data?.pay_rate) {
        toast.error(`${"Pay_Rate"} :${error?.response?.data?.pay_rate[0]}`);
      }

      if (error?.response?.data?.question) {
        toast.error(`${error?.response?.data?.question}`);
      }
    });

  yield axios
    .get(API_URL + `job/job-post/?limit=${LIMIT_DATA}`, AUTH_HEADERS())
    .then((resp) => {
      data.response = true;
      data.backend_data = resp.data;
    })
    .catch((error) => {
      console.log(error, "error");
    });

  yield put({ type: action_type.SET_JOB_POST_LIST, data });
  yield put({ type: action_type.SET_LOADING, payload: false });
}

export function* singleJobPostData(payload) {
  let data = { response: false, backend_data: null };
  yield put({ type: action_type.SET_LOADING, payload: true });

  yield axios
    .get(
      API_URL + `job/job-post/${payload?.payload}/?show_all=true`,
      AUTH_HEADERS()
    )
    .then((resp) => {
      data.response = true;
      data.backend_data = resp.data;
    })
    .catch((error) => {
      console.log(error, "error");
    });

  yield put({ type: action_type.SET_JOB_POST_DETAILS, data });
  yield put({ type: action_type.SET_LOADING, payload: false });
}

export function* getLanguageListData() {
  let data = { response: false, backend_data: null };
  yield put({ type: action_type.SET_LOADING, payload: true });

  let url;

  url = API_URL + `accounts/languages/`;

  yield axios
    .get(url, AUTH_HEADERS())
    .then((resp) => {
      data.response = true;
      data.backend_data = resp.data;
    })
    .catch((error) => {
      console.log(error, "error");
    });

  yield put({ type: action_type.SET_LANGUAGE_LIST, data });
  yield put({ type: action_type.SET_LOADING, payload: false });
}

export function* getExperienceLevelData() {
  let data = { response: false, backend_data: null };
  yield put({ type: action_type.SET_LOADING, payload: true });

  let url;

  url = API_URL + `job/experience/`;

  yield axios
    .get(url, AUTH_HEADERS())
    .then((resp) => {
      data.response = true;
      data.backend_data = resp.data;
    })
    .catch((error) => {
      console.log(error, "error");
    });

  yield put({ type: action_type.SET_EXPERIENCE_LEVEL_LIST, data });
  yield put({ type: action_type.SET_LOADING, payload: false });
}

export function* addExperienceLevelData(payload) {
  let data = { response: false, backend_data: null };
  yield put({ type: action_type.SET_LOADING, payload: true });

  let url;

  url = API_URL + `job/experience/`;

  yield axios
    .post(url, payload?.payload, AUTH_HEADERS())
    .then((resp) => {
      data.response = true;
      data.backend_data = resp.data;

      toast.success("Experience Created Successfully");
    })
    .catch((error) => {
      toast.error("Please Enter Experience Year");
    });

  yield axios
    .get(url, AUTH_HEADERS())
    .then((resp) => {
      data.response = true;
      data.backend_data = resp.data;
    })
    .catch((error) => {
      console.log(error, "error");
    });

  yield put({ type: action_type.SET_EXPERIENCE_LEVEL_LIST, data });
  yield put({ type: action_type.SET_LOADING, payload: false });
}

export function* editJobPostData(value) {
  let data = { response: false, backend_data: null };
  yield put({ type: action_type.SET_LOADING, payload: true });

  yield axios
    .patch(
      API_URL + `job/job-post/${value?.payload?.id}/`,
      value?.payload,
      AUTH_HEADERS()
    )
    .then((resp) => {
      data.response = true;
      data.backend_data = resp.data;

      if (data?.response) {
        value.data();
      }

      toast.success("Job Post Edit Successfully");
    })
    .catch((error) => {
      if (error?.response?.data?.title) {
        toast.error(`${"Title"} :${error?.response?.data?.title[0]}`);
      }

      if (error?.response?.data?.company_city) {
        toast.error(
          `${"Company_city"} :${error?.response?.data?.company_city[0]}`
        );
      }

      if (error?.response?.data?.company_name) {
        toast.error(
          `${"Company_name"} :${error?.response?.data?.company_name[0]}`
        );
      }

      if (error?.response?.data?.company_website) {
        toast.error(
          `${"Company_website"} :${error?.response?.data?.company_website[0]}`
        );
      }

      if (error?.response?.data?.detail) {
        toast.error(`${error?.response?.data?.detail}`);
      }

      if (error?.response?.data?.job_visibility) {
        toast.error("Please Select Job visibility choice");
      }

      if (error?.response?.data?.pay_rate) {
        toast.error(`${"Pay_Rate"} :${error?.response?.data?.pay_rate[0]}`);
      }

      if (error?.response?.data?.question) {
        toast.error(`${error?.response?.data?.question}`);
      }
    });

  yield axios
    .get(API_URL + `job/job-post/?limit=${LIMIT_DATA}`, AUTH_HEADERS())
    .then((resp) => {
      data.response = true;
      data.backend_data = resp.data;
    })
    .catch((error) => {
      console.log(error, "error");
    });

  yield put({ type: action_type.SET_JOB_POST_LIST, data });
  yield put({ type: action_type.SET_LOADING, payload: false });
}

export function* addQuestionsData(payload) {
  let data = { response: false, backend_data: null };
  yield put({ type: action_type.SET_LOADING, payload: true });

  yield axios
    .post(API_URL + `job/job-question/`, payload?.payload, AUTH_HEADERS())
    .then((resp) => {
      data.response = true;
      data.backend_data = resp.data;
    })
    .catch((error) => {
      console.log(error, "error");
    });

  yield axios
    .get(API_URL + `job/job-post/${payload?.payload?.job}/`, AUTH_HEADERS())
    .then((resp) => {
      data.response = true;
      data.backend_data = resp.data;
    })
    .catch((error) => {
      console.log(error, "error");
    });

  yield put({ type: action_type.SET_JOB_POST_DETAILS, data });
  yield put({ type: action_type.SET_LOADING, payload: false });
}

export function* editQuestionData(payload) {
  let data = { response: false, backend_data: null };
  yield put({ type: action_type.SET_LOADING, payload: true });

  yield axios
    .patch(
      API_URL + `job/job-question/${payload?.payload?.id}/`,
      payload?.payload?.qns,
      AUTH_HEADERS()
    )
    .then((resp) => {
      data.response = true;
      data.backend_data = resp.data;
    })
    .catch((error) => {
      console.log(error, "error");
    });

  yield axios
    .get(API_URL + `job/job-post/${payload?.payload?.job}/`, AUTH_HEADERS())
    .then((resp) => {
      data.response = true;
      data.backend_data = resp.data;
    })
    .catch((error) => {
      console.log(error, "error");
    });

  yield put({ type: action_type.SET_JOB_POST_DETAILS, data });
  yield put({ type: action_type.SET_LOADING, payload: false });
}

export function* deleteQuestionData(payload) {
  let data = { response: false, backend_data: null };
  yield put({ type: action_type.SET_LOADING, payload: true });

  yield axios
    .delete(
      API_URL + `job/job-question/${payload?.payload?.id}/`,
      payload?.payload?.data,
      AUTH_HEADERS()
    )
    .then((resp) => {
      data.response = true;
      data.backend_data = resp.data;
    })
    .catch((error) => {
      console.log(error, "error");
    });

  yield axios
    .get(
      API_URL + `job/job-post/${payload?.payload?.data?.job}/`,
      AUTH_HEADERS()
    )
    .then((resp) => {
      data.response = true;
      data.backend_data = resp.data;
    })
    .catch((error) => {
      console.log(error, "error");
    });

  yield put({ type: action_type.SET_JOB_POST_DETAILS, data });
  yield put({ type: action_type.SET_LOADING, payload: false });
}

export function* addNewSkillsData({ payload }) {
  let data = { response: false, backend_data: null };
  yield put({ type: action_type.SET_LOADING, payload: true });

  yield axios
    .post(API_URL + `resources/skill/`, payload, AUTH_HEADERS())
    .then((resp) => {
      data.response = true;
      data.backend_data = resp.data;

      toast.success("Skill Successfully Added");
    })
    .catch((error) => {
      console.log(error, "error");
    });

  yield axios.get(API_URL + "resources/skill/", HEADERS).then((resp) => {
    data.response = true;
    data.backend_data = resp.data;
  });

  yield put({ type: action_type.SET_SKILL_LIST, data });
  yield put({ type: action_type.SET_LOADING, payload: false });
}

export function* applicationListData(payload) {
  let data = { response: false, backend_data: null };
  yield put({ type: action_type.SET_LOADING, payload: true });

  let url;

  if (payload?.payload) {
    url =
      API_URL + `job/job-application/?limit=${LIMIT_DATA}${payload?.payload}`;
  } else {
    url = API_URL + `job/job-application/?limit=100`;
  }

  yield axios
    .get(url, AUTH_HEADERS())
    .then((resp) => {
      data.response = true;
      data.backend_data = resp.data;
    })
    .catch((error) => {
      console.log(error, "error");
    });

  yield put({ type: action_type.SET_APPLICATION_LIST_DATA, data });
  yield put({ type: action_type.SET_LOADING, payload: false });
}

export function* applicationChangeStatus(payload) {
  let data = { response: false, backend_data: null };
  yield put({ type: action_type.SET_LOADING, payload: true });

  yield axios
    .patch(
      API_URL + `job/job-application/${payload?.payload?.id}/`,
      payload?.payload,
      AUTH_HEADERS()
    )
    .then((resp) => {
      data.response = true;
      data.backend_data = resp.data;

      if (payload?.payload?.status == "1") {
        toast.success("Pending Successfully");
      }
      if (payload?.payload?.status == "2") {
        toast.success("Approved Successfully");
      }
      if (payload?.payload?.status == "3") {
        toast.success("Rejected Successfully");
      }
    })
    .catch((error) => {
      console.log(error, "error");
    });

  yield axios
    .get(API_URL + `job/job-application/?limit=${LIMIT_DATA}`, AUTH_HEADERS())
    .then((resp) => {
      data.response = true;
      data.backend_data = resp.data;
    })
    .catch((error) => {
      console.log(error, "error");
    });

  yield put({ type: action_type.SET_APPLICATION_LIST_DATA, data });
  yield put({ type: action_type.SET_LOADING, payload: false });
}
