import * as action_type from '../../../../Store/Constant'

export const companyLocationListReducer = (data = {response: false, backend_data: null }, action) => {
    switch (action.type) {
        case action_type.SET_SETTINGS_COMPANY_LIST:
            return action.data;
        default:
            return data
    }
}

export const businessUnitListReducer = (data = {response: false, backend_data: null }, action) => {
    switch (action.type) {
        case action_type.SET_BUSINESS_UNIT_LIST:
            return action.data;
        default:
            return data
    }
}

export const costCenterListReducer = (data = {response: false, backend_data: null }, action) => {
    switch (action.type) {
        case action_type.SET_COST_CENTER_LIST:
            return action.data;
        default:
            return data
    }
}

export const glAccountListReducer = (data = {response: false, backend_data: null }, action) => {
    switch (action.type) {
        case action_type.SET_GL_ACCOUNT_LIST:
            return action.data;
        default:
            return data
    }
}

export const unpscCodeListReducer = (data = {response: false, backend_data: null }, action) => {
    switch (action.type) {
        case action_type.SET_UNPSC_CODE_LIST:
            return action.data;
        default:
            return data
    }
}

export const taxGrouptListReducer = (data = {response: false, backend_data: null }, action) => {
    switch (action.type) {
        case action_type.SET_TAX_GROUP_LIST:
            return action.data;
        default:
            return data
    }
}

export const expCategoryListReducer = (data = {response: false, backend_data: null }, action) => {
    switch (action.type) {
        case action_type.SET_EXPERIENCE_CATEGORY_LIST:
            return action.data;
        default:
            return data
    }
}

export const serviceTaxListReducer = (data = {response: false, backend_data: null }, action) => {
    switch (action.type) {
        case action_type.SET_TAX_SERVICE_LIST:
            return action.data;
        default:
            return data
    }
}

export const paymentTermsListReducer = (data = {response: false, backend_data: null }, action) => {
    switch (action.type) {
        case action_type.SET_PAYMENT_TERM_LIST:
            return action.data;
        default:
            return data
    }
}

export const msaTypeListReducer = (data = {response: false, backend_data: null }, action) => {
    switch (action.type) {
        case action_type.SET_MSA_TYPE_LIST:
            return action.data;
        default:
            return data
    }
}

export const sowTypeListReducer = (data = {response: false, backend_data: null }, action) => {
    switch (action.type) {
        case action_type.SET_SOW_TYPE_LIST:
            return action.data;
        default:
            return data
    }
}

export const incomeTaxslabsListReducer = (data = {response: false, backend_data: null }, action) => {
    switch (action.type) {
        case action_type.SET_INCOME_TAX_SLABS_LIST:
            return action.data;
        default:
            return data
    }
}

export const privacySettingsListReducer = (data = {response: false, backend_data: null }, action) => {
    switch (action.type) {
        case action_type.SET_PRIVACY_SETTINGS_LIST:
            return action.data;
        default:
            return data
    }
}