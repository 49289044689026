import { put } from 'redux-saga/effects'
import * as action_type from '../../../../Store/Constant';
import { AUTH_MULTYPART_HEADERS, API_URL, AUTH_HEADERS, LIMIT_DATA } from '../../../../Axios/axiosData'
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

export function* resourceProfileData(payload) {

    let data = { response: false, backend_data: null }
    yield put({ type: action_type.SET_LOADING, payload: true })

    yield axios.get(API_URL + `resources/resources-profile/?limit=${LIMIT_DATA}&${payload.payload}`, AUTH_MULTYPART_HEADERS())
        .then(resp => {
            data.response = true
            data.backend_data = resp.data

        }).catch((error) => {
            toast.error('Error');
        })

    yield put({ type: action_type.SET_LOADING, payload: false })
    yield put({ type: action_type.SET_RESOURCE_PROFILE_LIST, data })

}


export function* viewResProfileData(payload) {

    let slug = payload.payload

    let data = { response: false, backend_data: null }
    yield put({ type: action_type.SET_LOADING, payload: true })

    yield axios.get(API_URL + `resources/resources-profile/${slug}/`, AUTH_MULTYPART_HEADERS())
        .then(resp => {
            
            data.response = true
            data.backend_data = resp.data

        }).catch((error) => {
            toast.error('Error');
        })

    yield put({ type: action_type.SET_LOADING, payload: false })
    yield put({ type: action_type.SET_VIEW_RES_PROFILE, data })

}

export function* getLoginUserProfileData(payload) {

    const { type , id } = payload?.payload ;

    let url = ``

    if(type === 'agency'){
        url = `agency/agency-profile/${id}/`
    }else if(type === 'resource'){
        url = `resources/resources-profile/${id}/`
    }else{
        url = `organisation/profile/${id}/`
    }

    let data = { response: false, backend_data: null }
    yield put({ type: action_type.SET_LOADING, payload: true })

    yield axios.get(API_URL + url , AUTH_MULTYPART_HEADERS())
        .then(resp => {
            
            data.response = true
            data.backend_data = resp.data

        }).catch((error) => {
            toast.error('Error');
        })

    yield put({ type: action_type.SET_LOADING, payload: false })
    yield put({ type: action_type.SET_LOGIN_USER_PROFILE_DETAILS, data })

}


export function* viewAgnProfileData(payload) {

    let slug = payload.payload

    let data = { response: false, backend_data: null }
    yield put({ type: action_type.SET_LOADING, payload: true })

    yield axios.get(API_URL + `agency/agency-profile/${slug}/`, AUTH_MULTYPART_HEADERS())
        .then(resp => {

            data.response = true
            data.backend_data = resp?.data

        }).catch((error) => {
            toast.error('Error');
        })

    yield put({ type: action_type.SET_LOADING, payload: false })
    yield put({ type: action_type.SET_VIEW_AGN_PROFILE, data })

}