import * as action_type from '../../../../Store/Constant'

export const organisationUserListReducer = (data = {response: false, backend_data: null }, action) => {
    switch (action.type) {
        case action_type.SET_ORGANISATION_USERS_LIST:
            return action.data;
        default:
            return data
    }
}

export const agencyUserListReducer = (data = {response: false, backend_data: null }, action) => {
    switch (action.type) {
        case action_type.SET_AGENCY_USERS_LIST:
            return action.data;
        default:
            return data
    }
}

export const orgUserDetailsReducer = (data = {response: false, backend_data: null }, action) => {
    switch (action.type) {
        case action_type.SET_ORG_USER_DETAILS_DATA:
            return action.data;
        default:
            return data
    }
}

export const agnUserDetailsReducer = (data = {response: false, backend_data: null }, action) => {
    switch (action.type) {
        case action_type.SET_AGN_USER_DETAILS_DATA:
            return action.data;
        default:
            return data
    }
}

