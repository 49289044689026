import { call, put } from "redux-saga/effects";
import * as action_type from "../../../../Store/Constant";
import {
  AUTH_MULTYPART_HEADERS,
  API_URL,
  AUTH_HEADERS,
  LIMIT_DATA,
} from "../../../../Axios/axiosData";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

export function* msaListForExcel(payload) {
  let data = { response: false, backend_data: null };
  yield put({ type: action_type.SET_LOADING, payload: true });

  yield axios
    .get(API_URL + `msa/master-agreement/?${payload?.payload}`, AUTH_HEADERS())
    .then((resp) => {
      data.response = true;
      data.backend_data = resp.data;
    })
    .catch((error) => {
      console.log(error, "error");
    });

  yield put({ type: action_type.SET_LOADING, payload: false });
  yield put({ type: action_type.SET_MSA_DATA_EXCEL, data });
}

// export function* sowListForExcel(payload) {

//     let data = { response: false, backend_data: null }
//     yield put({ type: action_type.SET_LOADING, payload: true })

//     yield axios.get(API_URL + `sow/statement-of-work/?${payload?.payload}`, AUTH_HEADERS(),)
//         .then(resp => {
//             data.response = true
//             data.backend_data = resp.data
//         }).catch((error) => {
//             console.log(error, "error")
//         })

//     yield put({ type: action_type.SET_LOADING, payload: false })
//     yield put({ type: action_type.SET_SOW_DATA_EXCEL, data })
// }
export function* sowListForExcel(payload) {
  let data = { response: false, backend_data: null };

  yield put({ type: action_type.SET_LOADING, payload: true });

  try {
    console.time("SOW API Call"); // Start timing the API request
    const response = yield call(
      axios.get,
      `${API_URL}sow/statement-of-work/?${payload?.payload}`,
      AUTH_HEADERS()
    );
    console.timeEnd("SOW API Call"); // End timing and log it

    data = { response: true, backend_data: response.data };
  } catch (error) {
    console.error("SOW API Error:", error);
  }

  yield put({ type: action_type.SET_LOADING, payload: false });
  yield put({ type: action_type.SET_SOW_DATA_EXCEL, data });
}

export function* timesheetListForExcel(payload) {
  let data = { response: false, backend_data: null };
  yield put({ type: action_type.SET_LOADING, payload: true });

  yield axios
    .get(API_URL + `timesheet/timesheet/?${payload?.payload}`, AUTH_HEADERS())
    .then((resp) => {
      data.response = true;
      data.backend_data = resp.data;
    })
    .catch((error) => {
      console.log(error, "error");
    });

  yield put({ type: action_type.SET_LOADING, payload: false });
  yield put({ type: action_type.SET_TIMESHEET_DATA_EXCEL, data });
}

export function* invoiceListForExcel(payload) {
  let data = { response: false, backend_data: null };
  yield put({ type: action_type.SET_LOADING, payload: true });

  yield axios
    .get(API_URL + `invoice/invoice/?${payload?.payload}`, AUTH_HEADERS())
    .then((resp) => {
      data.response = true;
      data.backend_data = resp.data;
    })
    .catch((error) => {
      console.log(error, "error");
    });

  yield put({ type: action_type.SET_INVOICE_DATA_EXCEL, data });
  yield put({ type: action_type.SET_LOADING, payload: false });
}

export function* roleListForExcel(payload) {
  let data = { response: false, backend_data: null };
  yield put({ type: action_type.SET_LOADING, payload: true });

  yield axios
    .get(API_URL + `accounts/role/?${payload?.payload}`, AUTH_HEADERS())
    .then((resp) => {
      data.response = true;
      data.backend_data = resp.data;
    })
    .catch((error) => {
      console.log(error, "error");
    });

  yield put({ type: action_type.SET_ROLE_DATA_EXCEL, data });
  yield put({ type: action_type.SET_LOADING, payload: false });
}

export function* usersListForExcel(payload) {
  let data = { response: false, backend_data: null };
  yield put({ type: action_type.SET_LOADING, payload: true });

  yield axios
    .get(API_URL + `${payload?.payload}`, AUTH_HEADERS())
    .then((resp) => {
      data.response = true;
      data.backend_data = resp.data;
    })
    .catch((error) => {
      console.log(error, "error");
    });

  yield put({ type: action_type.SET_USERS_DATA_EXCEL, data });
  yield put({ type: action_type.SET_LOADING, payload: false });
}
