import { put } from "redux-saga/effects";
import * as action_type from "../../../../Store/Constant";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import {
  API_URL,
  AUTH_MULTYPART_HEADERS,
  AUTH_HEADERS,
  LIMIT_DATA,
  //   AUTH_MULTYPART_HEADERS2,
} from "../../../../Axios/axiosData";

export function* msaFieldSaga() {
  let data = { response: false, backend_data: {} };
  yield put({ type: action_type.SET_LOADING, payload: true });

  yield axios
    .get(API_URL + "msa/msa-fields/", AUTH_MULTYPART_HEADERS())
    .then((resp) => {
      data.response = true;
      data.backend_data = resp.data.payload;

      for (let i of data.backend_data.msa_type) {
        i.label = i.name;
      }

      for (let i of data.backend_data.unpsc_code) {
        i.label = i.name;
      }

      for (let i of data.backend_data.tax_service_type) {
        i.label = i.name;
      }

      for (let i of data.backend_data.tax_group) {
        i.label = i.name;
      }

      for (let i of data.backend_data.business_unit) {
        i.label = i.name;
      }

      for (let i of data.backend_data.gl_account) {
        i.label = i.name;
      }
    });

  yield put({ type: action_type.SET_MSA_FIELD, data });
  yield put({ type: action_type.SET_LOADING, payload: false });
}

export function* approvalAllUsersData({ payload }) {
  let data = { response: false, backend_data: {} };
  yield put({ type: action_type.SET_LOADING, payload: true });

  yield axios
    .get(API_URL + `accounts/custom-user/?${payload}`, AUTH_HEADERS())
    .then((resp) => {
      data.response = true;
      data.backend_data = resp.data;
    });

  yield put({ type: action_type.SET_APPROVAL_ALL_USERS, data });
  yield put({ type: action_type.SET_LOADING, payload: false });
}

// export function* getRsoureListSaga({ path, url }) {
export function* getRsoureListSaga(payload) {
  let main_url;

  if (payload?.payload) {
    main_url =
      API_URL + `resources/resources-profile/?limit=${10}${payload?.payload}`;
  } else {
    main_url = API_URL + `resources/resources-profile/?limit=500`;
  }

  let data = { response: false, backend_data: null };
  yield put({ type: action_type.SET_LOADING, payload: true });
  yield axios.get(main_url, AUTH_MULTYPART_HEADERS()).then((resp) => {
    data.response = true;
    data.backend_data = resp.data;
  });

  yield put({ type: action_type.SET_LOADING, payload: false });
  yield put({ type: action_type.SET_RESOURCELIST, data });
}
// export function* getAgencyMyListSaga(payload) {
//   let my_url;
//   //   main_url = API_URL + `/organisation/my-agencies/?limit=500`;

//   if (payload?.payload) {
//     my_url = `https://api.agreementpaper.site/organisation/my-agencies/?limit=${LIMIT_DATA}${payload?.payload}`;
//   }
//   let data = { response: false, backend_data: null };
//   yield put({ type: action_type.SET_LOADING, payload: true });

//   yield axios
//     .get(my_url, AUTH_MULTYPART_HEADERS2())
//     .then((resp) => {
//       data.response = true;
//       data.backend_data = resp.data;
//     })
//     .catch((err) => {
//       console.log("err", err);
//     });

//   yield put({ type: action_type.SET_AGENCY_MY_LIST, data });
//   yield put({ type: action_type.SET_LOADING, payload: false });
// }
export function* getAgencyListSaga(payload) {
  let main_url;

  if (payload?.payload) {
    main_url =
      API_URL + `agency/agency-profile/?limit=${LIMIT_DATA}${payload?.payload}`;
    // main_url = API_URL + `/organisation/my-agencies/?limit=${LIMIT_DATA}${payload?.payload}`
  } else {
    // main_url = API_URL + `agency/agency-profile/?limit=500`
    main_url = API_URL + `/organisation/my-agencies/?limit=500`;
    // main_url = `https://api.agreementpaper.site/organisation/my-agencies/?limit=500`;
  }
  // if (url) {
  //     main_url = url
  // }
  // else {
  // main_url = API_URL + `agency/agency-profile/?limit=${LIMIT_DATA}${payload?.payload}`
  // + path
  // }

  let data = { response: false, backend_data: null };
  yield put({ type: action_type.SET_LOADING, payload: true });

  yield axios
    .get(main_url, AUTH_MULTYPART_HEADERS())
    .then((resp) => {
      data.response = true;
      data.backend_data = resp.data;
    })
    .catch((err) => {
      console.log("err", err);
    });

  yield put({ type: action_type.SET_AGENCY_PROFILE_LIST, data });
  yield put({ type: action_type.SET_LOADING, payload: false });
}

export function* getAddMsaSaga({ payload }) {
  let data = { response: false, backend_data: null };
  let form_data = payload;

  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month?.length < 2) month = "0" + month;
    if (day?.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  form_data.start_date = `${formatDate(form_data?.start_date)} 00:00:00`;
  form_data.end_date = `${formatDate(form_data?.end_date)} 00:00:00`;

  yield axios
    .post(API_URL + "msa/master-agreement/", form_data, AUTH_HEADERS())
    .then((resp) => {
      data.response = true;
      data.addMsa = resp.data;

      toast.success(" Master Service Agreement created successfully!");

      if (payload?.user == "organisation") {
        data.path = "/organisation/msa/" + resp.data.slug;
      }
      if (payload?.user == "agency") {
        data.path = "/agency/msa/" + resp.data.slug;
      }
    })
    .catch((error) => {
      if (error.response.data.error.length > 0) {
        toast.error(`${"Error"} : ${error?.response?.data?.error[0]}`);
      }
      if (error?.response?.data?.budget) {
        toast.error(`${"Budget"} : ${error?.response?.data?.budget[0]}`);
      }

      if (error?.response?.data?.name) {
        toast.error(`${"Name"} : ${error?.response?.data?.name[0]}`);
      }

      if (error?.response?.data?.savings_percentage) {
        toast.error(
          `${"Percentage"} : ${error?.response?.data?.savings_percentage[0]}`
        );
      }

      if (error?.response?.data?.start_date) {
        toast.error("Invalid Start date ,Please Select !");
      }

      if (error?.response?.data?.end_date) {
        toast.error("Invalid End date ,Please Select !");
      }
    });

  yield put({ type: action_type.GET_NAVIGATE, data });
  yield put({ type: action_type.SET_ADDMSA, data });
  // console.log("data", data);
  // return data;
}

// export function* getEditMsa(payload) {
//   let data = { response: false, backend_data: null };

//   let form_data = payload?.payload?.payload;

//   function formatDate(date) {
//     var d = new Date(date),
//       month = "" + (d.getMonth() + 1),
//       day = "" + d.getDate(),
//       year = d.getFullYear();

//     if (month?.length < 2) month = "0" + month;
//     if (day?.length < 2) day = "0" + day;

//     return [year, month, day].join("-");
//   }

//   form_data.start_date = formatDate(form_data?.start_date);
//   form_data.end_date = formatDate(form_data?.end_date);

//   form_data.start_date = `${form_data?.start_date} 00:00:00`;
//   form_data.end_date = `${form_data?.end_date} 00:00:00`;

//   yield axios
//     .patch(
//       API_URL + `msa/master-agreement/${payload?.payload?.slug}/`,
//       form_data,
//       AUTH_MULTYPART_HEADERS()
//     )
//     .then((resp) => {
//       data.response = true;
//       data.addMsa = resp.data;
//       toast.success("MSA edited successfully!");

//       data.path = resp.data.slug;
//     })
//     .catch((err) => {
//       console.log("err", err);
//       data.backend_data = err;
//       toast.error(err.response.data.error);
//     });

//   yield put({ type: action_type.GET_NAVIGATE, data });
// }
export function* getEditMsa(payload) {
  let data = { response: false, backend_data: null };

  let form_data = { ...payload?.payload?.payload };

  function formatDate(date) {
    if (!date) return null;
    let d = new Date(date);
    let month = (d.getMonth() + 1).toString().padStart(2, "0");
    let day = d.getDate().toString().padStart(2, "0");
    let year = d.getFullYear();
    return `${year}-${month}-${day}`;
  }

  if (form_data.start_date)
    form_data.start_date = `${formatDate(form_data.start_date)} 00:00:00`;
  if (form_data.end_date)
    form_data.end_date = `${formatDate(form_data.end_date)} 00:00:00`;

  try {
    const response = yield axios.patch(
      `${API_URL}msa/master-agreement/${payload?.payload?.slug}/`,
      form_data,
      AUTH_MULTYPART_HEADERS()
    );

    data.response = true;
    data.addMsa = response.data;
    toast.success("MSA edited successfully!");

    data.path = response.data.slug;
  } catch (err) {
    console.log("Error:", err);
    data.backend_data = err;
    toast.error(err?.response?.data?.error || "An error occurred");
  }

  yield put({ type: action_type.GET_NAVIGATE, data });
}
