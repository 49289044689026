import { put } from "redux-saga/effects";
import * as action_type from "../../../../Store/Constant";
import {
  AUTH_MULTYPART_HEADERS,
  API_URL,
  AUTH_HEADERS,
  HEADERS,
  LIMIT_DATA,
} from "../../../../Axios/axiosData";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

export function* getCreateInviteResource(payload) {
  let data = { response: false, backend_data: null };
  let formValue = payload?.payload;

  yield axios
    .post(API_URL + "accounts/invite-user/create/", formValue, AUTH_HEADERS())
    .then((resp) => {
      data.response = true;
      data.backend_data = resp.data;

      if (payload?.data) {
        payload?.data();
      }

      toast.success(resp?.data?.message);
    })
    .catch((error) => {
      if (error?.response?.data?.email) {
        toast.error(`${"Email"} : ${error?.response?.data?.email[0]}`);
      }

      if (error?.response?.data?.user) {
        toast.error(`${"User"} : ${error?.response?.data?.user}`);
      }
    });

  yield put({ type: action_type.GET_NAVIGATE, data });
}

export function* viewMyTeamData(payload) {
  let slug = payload.payload;

  let data = { response: false, backend_data: null };
  yield put({ type: action_type.SET_LOADING, payload: true });

  yield axios
    .get(
      API_URL + `resources/resources-profile/${slug}/?my_team=true`,
      AUTH_HEADERS()
    )
    .then((resp) => {
      data.response = true;
      data.backend_data = resp.data;
    })
    .catch((error) => {
      toast.error("Error");
    });

  yield put({ type: action_type.SET_LOADING, payload: false });
  yield put({ type: action_type.SET_MY_TEAM_USER_DETAILS, data });
}

export function* getPostContract(payload) {
  let data = { response: false, backend_data: null };
  let formValue = payload?.payload;
const urlOld= API_URL + "settings/analyze-contract/"// previous;
const urlNew= API_URL + "ai/analyze-contract/"//new
  yield axios
    .post(
      urlNew,
      formValue,
      AUTH_MULTYPART_HEADERS()
    )
    .then((resp) => {
      data.response = true;
      data.backend_data = resp.data;

      if (payload?.data) {
        payload?.data();
      }

      toast.success(resp?.data?.message);
    })
    .catch((error) => {
      toast.error(`Unreadable - Contact Error`);
      if (payload?.data) {
        payload?.data();
      }
    });

  yield put({ type: action_type.GET_NAVIGATE, data });
  yield put({ type: action_type.SET_ANALYZE_CONTRACT, data }); 
}
export function* getAnalyzeByAI(payload) {
  let data = { response: false, backend_data: null };
  let formValue = payload?.payload;

  yield axios.post(API_URL + "settings/openai/analyze-contract/",formValue, AUTH_HEADERS())
    .then((resp) => {
      data.response = true;
      data.backend_data = resp.data;

      if (payload?.data) {
        payload?.data();
      }
      payload.callback(resp.data)
      // toast.success(resp?.data?.message);
    })
    .catch((error) => {
      payload.callback(error)
      toast.error(`Unreadable - Contact Error`);
      if (payload?.data) {
        payload?.data();
      }
    });

  yield put({ type: action_type.GET_NAVIGATE, data });
  yield put({ type: action_type.SET_ANALYZE_BY_AI, data });
}

export function* getUserResetPassword(payload) {
  let data = { response: false, backend_data: null };
  let formValue = payload?.payload;

  yield axios
    .post(
      API_URL + "accounts/password-reset/",
      formValue,
      AUTH_MULTYPART_HEADERS()
    )
    .then((resp) => {
      data.response = true;
      data.backend_data = resp.data;

      if (payload?.data) {
        payload?.data();
      }

      toast.success(resp?.data?.message);
    })
    .catch((error) => {
      if (error?.response?.data?.email) {
        toast.error(`${"Email"} : ${error?.response?.data?.email[0]}`);
      }

      if (error?.response?.data?.user) {
        toast.error(`${"User"} : ${error?.response?.data?.user}`);
      }
    });

  yield put({ type: action_type.GET_NAVIGATE, data });
}

export function* getResourceReserveRequest(payload) {
  let data = { response: false, backend_data: null };
  let formValue = payload?.payload;

  yield axios
    .post(API_URL + "common/request-reserve/", formValue, AUTH_HEADERS())
    .then((resp) => {
      data.response = true;
      data.backend_data = resp.data;

      if (payload?.data) {
        payload?.data();
      }

      toast.success("Email Send Successfully");
    })
    .catch((error) => {
      toast.error("Error");
    });

  yield put({ type: action_type.GET_NAVIGATE, data });
}

export function* getResourceAcceptReserveRequest(payload) {
  let data = { response: false, backend_data: null };
  let formValue = payload?.payload;

  yield axios
    .get(
      API_URL +
        `resources/accept-request/?user_id=${formValue?.user_id}&resource_id=${formValue?.resource_id}`,
      AUTH_HEADERS()
    )
    .then((resp) => {
      data.response = true;
      data.backend_data = resp.data;

      if (payload?.data) {
        payload?.data();
      }

      toast.success(resp?.data?.message);
    })
    .catch((error) => {
      // if (error) {
      //   toast.error('Error');
      // }
    });

  yield put({ type: action_type.GET_NAVIGATE, data });
}
