import { put, call } from "redux-saga/effects";
import * as action_type from "../../../../Store/Constant";
import { API_URL } from "../../../../Axios/axiosData";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

// export function* sendEmailForgot(payload) {
//   let data = { response: false, backend_data: null };
//   yield put({ type: action_type.SET_LOADING, payload: true });

//   yield axios
//     .post(
//       API_URL + `accounts/forget-password/?resend=${true}`,
//       payload?.payload
//     )
//     .then((resp) => {
//       toast.success(resp?.data?.message);
//       // setDisabledButton(false)
//       data.response = true;
//       data.backend_data = resp.data;
//     })
//     .catch((error) => {
//       // setDisabledButton(false)
//       toast.error(error.response.data.email);
//     });

//   yield put({ type: action_type.SET_FORGOT_SEND_EMAIL, data });
//   yield put({ type: action_type.SET_LOADING, payload: false });
// }

export function* sendEmailForgot(action) {
  const { payload } = action;
  const { setDisabledButton, ...restPayload } = payload;
  let data = { response: false, backend_data: null };
  try {
    yield put({ type: action_type.SET_LOADING, payload: true });
    const response = yield call(
      axios.post,
      `${API_URL}accounts/forget-password/?resend=true`,
      restPayload
    );
    toast.success(response?.data?.message);
    data.response = true;
    data.backend_data = response.data;
  } catch (error) {
    toast.error(error.response.data.email);
  } finally {
    if (setDisabledButton) {
      setDisabledButton(false);
    }
    yield put({ type: action_type.SET_FORGOT_SEND_EMAIL, data });
    yield put({ type: action_type.SET_LOADING, payload: false });
  }
}

export function* verifyForgotOTP(payload) {
  let data = { response: false, backend_data: null };
  yield put({ type: action_type.SET_LOADING, payload: true });

  yield axios
    .post(
      API_URL + `accounts/forget-password-reset/`,
      payload?.payload
    )
    .then((resp) => {
      data.response = true;
      data.backend_data = resp.data;

      if(data.response){
        toast.success(`${resp?.data?.message}`);
      }
    })
    .catch((error) => {
      if(error?.response?.data?.code){
        toast.error(`${error?.response?.data?.code}`);
      }else{
        toast.error(`Error : Invalid Code`);
      }
    });

  yield put({ type: action_type.SET_VERITY_FORGOT_OTP, data });
  yield put({ type: action_type.SET_LOADING, payload: false });
}
