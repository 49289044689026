import * as action_type from "./Constant";

const initialState = {
  initialProfile: { response: false, backend_data: null },
  notifications: { response: false, backend_data: null },
  notificationReq: { response: false, backend_data: null },
  allPlans: { response: false, backend_data: null },
  purchasedPlans: { response: false, backend_data: null },
  currentPlans: { response: false, backend_data: null },
  aiTemplate: { response: false, backend_data: null },
  pdfData: { response: false, backend_data: null },
  docxData: { response: false, backend_data: null },
  allSignDocs: { response: false, backend_data: null },
  allInvoices: { response: false, backend_data: null },
  masterDataAll: { response: false, backend_data: null },
  // Add other initial states here if needed
};

export const commonDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case action_type.SET_RES_INITIAL_PROFILE:
      return {
        ...state,
        initialProfile: action.data,
      };
    case action_type.SET_NOTIFICATION_REQ:
      return {
        ...state,
        notifications: action.data,
      };

    case action_type.SET_COMMON_REQ:
      return {
        ...state,
        notificationReq: action.data,
      };
    case action_type.SET_UPDATE_NOTIFICATION_REQ:
      return {
        ...state,
        notificationUpdate: action.data,
      };
    case action_type.SET_ALL_PLANS:
      return {
        ...state,
        allPlans: action.data,
      };
    case action_type.SET_PURCHASE_PLAN:
      return {
        ...state,
        purchasedPlans: action.data,
      };
    case action_type.SET_CURRENT_PLAN:
      return {
        ...state,
        currentPlans: action.data,
      };
    case action_type.SET_SAVE_AI_TEMPLATE:
      return {
        ...state,
        aiTemplate: action.data,
      };
    case action_type.SET_UPDATE_AI_TEMPLATE:
      return {
        ...state,
        aiTemplate: action.data,
      };
    case action_type.SET_DOWNLOAD_AI_TEMPLATE_PDF:
      return {
        ...state,
        pdfData: action.data,
      };
    case action_type.SET_DOWNLOAD_AI_TEMPLATE_DOCX:
      return {
        ...state,
        docxData: action.data,
      };
    case action_type.SET_ALL_ESIGN_DOCS:
      return {
        ...state,
        allSignDocs: action.data,
      };
    case action_type.SET_INVOICES:
      return {
        ...state,
        allInvoices: action.data,
      };
    case action_type.SET_MASTERDATARESOURCE_ALL:
      return {
        ...state,
        masterDataAll: action.data,
      };

    case action_type.SET_AI_DRAFT_TEMPLATES_SUCCESS:
      console.log("Fetched Templates in Reducer:", action.payload);
      return {
        ...state,
        aiTemplate: {
          response: action.payload.response,
          backend_data: action.payload.backend_data, // ✅ Store templates correctly
          totalRecords: action.payload.count,
        },
      };

    // Add more cases for other state updates here
    default:
      return state;
  }
};
