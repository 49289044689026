// import React from "react";
// import LoadingScreen from "react-loading-screen";
// import LoderVJ from "./../../../images/LoaderVJ.svg";

// function Loader() {
//   return (
//     <div>
//       <img src={LoderVJ} alt="Loading..." />
//       {/* <LoadingScreen
//         loading={true}
//         bgColor="rgba(255,255,255,0.8)"
//         spinnerColor="#686EFF"
//         textColor="#686EFF !important"
//         logoSrc=""
//         text="Loading..."
//       >
//       </LoadingScreen> */}
//     </div>
//   );
// }

// export default Loader;
import React from "react";
import LoderVJ from "./../../../images/LoaderVJ.svg";
import AgreementLogo from "./../../../images/aggrement_onLgt_logo.png";
function Loader() {
  return (
    <div style={styles.container}>
      <img src={LoderVJ} alt="Loading..." style={styles.loader} />
      <img src={AgreementLogo} alt="Loading..." />
      {/* <h3>Agreement Paper</h3> */}
    </div>
  );
}

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh", // Full screen height
    width: "100vw", // Full screen width
    backgroundColor: "#fff", // Background color (optional)
  },
  loader: {
    width: "100px",
    height: "100px",
  },
};

export default Loader;
