import * as action_type from '../../../../Store/Constant'

export const jobPostDataReducer = (data = {response: false, backend_data: null }, action) => {
    switch (action.type) {
        case action_type.SET_JOB_POST_LIST:
            return action.data;
        default:
            return data
    }
}

export const languageListReducer = (data = {response: false, backend_data: null }, action) => {
    switch (action.type) {
        case action_type.SET_LANGUAGE_LIST:
            return action.data;
        default:
            return data
    }
}

export const experienceListReducer = (data = {response: false, backend_data: null }, action) => {
    switch (action.type) {
        case action_type.SET_EXPERIENCE_LEVEL_LIST:
            return action.data;
        default:
            return data
    }
}

export const jobPostDetailsReducer = (data = {response: false, backend_data: null }, action) => {
    switch (action.type) {
        case action_type.SET_JOB_POST_DETAILS:
            return action.data;
        default:
            return data
    }
}

export const applicationListReducer = (data = {response: false, backend_data: null }, action) => {
    switch (action.type) {
        case action_type.SET_APPLICATION_LIST_DATA:
            return action.data;
        default:
            return data
    }
}

