// import {createStore} from 'redux'
//  import {configureStore} from '@reduxjs/toolkit'
//  import RootReducer from './RootReducer';
// import RootSaga from './RootSaga'
// import createSagaMiddleware from 'redux-saga';

// const sagaMiddleware = createSagaMiddleware();
// const store  = configureStore({
//     reducer:RootReducer,
//     middleware:()=>[sagaMiddleware]
// });

// sagaMiddleware.run(RootSaga);
// export default store;


import { createStore, applyMiddleware, compose } from "redux"
import createSagaMiddleware from 'redux-saga';
import RootSaga from './RootSaga';
import RootReducer from './RootReducer';

const composeEnhancers = (window).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const sagaMiddleware = createSagaMiddleware();


const store = createStore(
    RootReducer,
    composeEnhancers(applyMiddleware(sagaMiddleware))
  )

sagaMiddleware.run(RootSaga);

export default store