import * as action_type from '../../../../Store/Constant'

export const showListReducer = (data = { loading: false }, action) => {
    switch (action.type) {
        case action_type.SET_SHOW_LIST:
            return action.data
        default:
            return data
    }
}

export const sowDetailReducer = (data = { sow: {} }, action) => {

    switch (action.type) {
        case action_type.SET_SOW_DETAIL:
            return action.data
        case action_type.EDIT_SOW_DETAIL:
            let new_data = data?.sow
            let { key, val } = action
            new_data[key] = val
            return { sow: new_data }

        default:
            return data
    }

}

export const sowFieldReducer = (data = { loading: false }, action) => {
    switch (action.type) {
        case action_type.SET_SOW_FIELD:
            return action.data
        default:
            return data
    }
}

export const allMsaListReducer = (data = { loading: false }, action) => {
    switch (action.type) {
        case action_type.SET_MSA_LIST:
            return action.data
        default:
            return data
    }
}

export const allOrganisationListReducer = (data = { loading: false }, action) => {
    switch (action.type) {
        case action_type.SET_ORGANISATION_LIST:
            return action.data
        default:
            return data
    }
}
export const OrganisationDetailsReducer = (data = { loading: false }, action) => {
    switch (action.type) {
        case action_type.SET_ORG_BY_ID:
            return action.data
        default:
            return data
    }
}

export const allMsaFiltersListReducer = (data = { loading: false }, action) => {
    switch (action.type) {
        case action_type.SET_MSA_FILTERS_LIST:
            return action.data
        default:
            return data
    }
}

export const retriveMsaReducer = (data = { response: false, backend_data: null }, action) => {
    switch (action.type) {
        case action_type.SET_MSA_DETAIL:
            return action.data
        default:
            return data
    }
}