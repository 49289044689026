import { put } from "redux-saga/effects";
import * as action_type from "../../../../Store/Constant";
import {
  AUTH_MULTYPART_HEADERS,
  API_URL,
  AUTH_HEADERS,
  LIMIT_DATA,
  HEADERS,
} from "../../../../Axios/axiosData";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

export function* getSingleUserRatingsReview(payload) {
  let data = { response: false, backend_data: null };
  yield put({ type: action_type.SET_LOADING, payload: true });

  let url = ``;

  if (payload?.payload?.name === "resource") {
    url = API_URL + `common/rating/?resource=${payload?.payload?.id}`;
  } else {
    url = API_URL + `common/rating/?agency=${payload?.payload?.id}`;
  }

  yield axios
    .get(url, AUTH_HEADERS())
    .then((resp) => {
      data.response = true;
      data.backend_data = resp.data;
    })
    .catch((error) => {
      console.log(error, "error");
    });

  yield put({ type: action_type.SET_SINGLE_USER_RATING_REVIEW, data });
  yield put({ type: action_type.SET_LOADING, payload: false });
}

export function* postDataRatingsReview(payload) {
  let data = { response: false, backend_data: null };
  yield put({ type: action_type.SET_LOADING, payload: true });

  yield axios
    .post(API_URL + `common/rating/`, payload?.payload, AUTH_HEADERS())
    .then((resp) => {
      data.response = true;
      data.backend_data = resp.data;

      if (data?.response) {
        payload.data();
      }
    })
    .catch((error) => {
      if (error?.response?.data?.detail) {
        toast.error(`${"Detail"} : ${error?.response?.data?.detail[0]}`);
      }
    });

  let url = ``;

  if (payload?.payload?.resource !== null) {
    url = API_URL + `common/rating/?resource=${payload?.payload?.resource}`;
  } else {
    url = API_URL + `common/rating/?agency=${payload?.payload?.agency}`;
  }

  yield axios
    .get(url, AUTH_HEADERS())
    .then((resp) => {
      data.response = true;
      data.backend_data = resp.data;
    })
    .catch((error) => {
      console.log(error, "error");
    });

  yield put({ type: action_type.SET_SINGLE_USER_RATING_REVIEW, data });
  yield put({ type: action_type.SET_LOADING, payload: false });
}

export function* editRatingsReview(payload) {
  let data = { response: false, backend_data: null };
  yield put({ type: action_type.SET_LOADING, payload: true });

  yield axios
    .patch(
      API_URL + `common/rating/${payload?.payload?.id}/`,
      payload?.payload,
      AUTH_HEADERS()
    )
    .then((resp) => {
      data.response = true;
      data.backend_data = resp.data;

      if (data?.response) {
        payload.data();
      }
    })
    .catch((error) => {
      if (error?.response?.data?.detail) {
        toast.error(`${"Detail"} : ${error?.response?.data?.detail[0]}`);
      }
    });

  let url = ``;

  if (payload?.payload?.resource !== null) {
    url = API_URL + `common/rating/?resource=${payload?.payload?.resource}`;
  } else {
    url = API_URL + `common/rating/?agency=${payload?.payload?.agency}`;
  }

  yield axios
    .get(url, AUTH_HEADERS())
    .then((resp) => {
      data.response = true;
      data.backend_data = resp.data;
    })
    .catch((error) => {
      console.log(error, "error");
    });

  yield put({ type: action_type.SET_SINGLE_USER_RATING_REVIEW, data });
  yield put({ type: action_type.SET_LOADING, payload: false });
}

export function* deleteSigleUsersRating(payload) {
  let data = { response: false, backend_data: null };
  yield put({ type: action_type.SET_LOADING, payload: true });

  yield axios
    .delete(API_URL + `common/rating/${payload?.payload?.id}/`, AUTH_HEADERS())
    .then((resp) => {
      data.response = true;
      data.backend_data = resp.data;

      if (data?.response) {
        payload?.data();
      }
    })
    .catch((error) => {
      if (error?.response?.data?.detail) {
        toast.error(`${"Detail"} : ${error?.response?.data?.detail[0]}`);
      }
    });

  let url = ``;

  if (payload?.payload?.resource !== null) {
    url = API_URL + `common/rating/?resource=${payload?.payload?.resource}`;
  } else {
    url = API_URL + `common/rating/?agency=${payload?.payload?.agency}`;
  }

  yield axios
    .get(url, AUTH_HEADERS())
    .then((resp) => {
      data.response = true;
      data.backend_data = resp.data;
    })
    .catch((error) => {
      console.log(error, "error");
    });

  yield put({ type: action_type.SET_SINGLE_USER_RATING_REVIEW, data });
  yield put({ type: action_type.SET_LOADING, payload: false });
}

export function* getEditDataRatingsReview(payload) {
  let data = { response: false, backend_data: null };
  yield put({ type: action_type.SET_LOADING, payload: true });

  yield axios
    .get(API_URL + `common/rating/${payload?.payload}/`, AUTH_HEADERS())
    .then((resp) => {
      data.response = true;
      data.backend_data = resp.data;
    })
    .catch((error) => {
      console.log(error, "error");
    });

  yield put({ type: action_type.SET_EDIT_DETAILS_RATING_REVIEW, data });
  yield put({ type: action_type.SET_LOADING, payload: false });
}
