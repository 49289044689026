import { put } from "redux-saga/effects";
import * as action_type from "../../../../Store/Constant";
import {
  AUTH_MULTYPART_HEADERS,
  API_URL,
  AUTH_HEADERS,
  LIMIT_DATA,
} from "../../../../Axios/axiosData";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

export function* orgDashboardData() {
  let data = { response: false, backend_data: null };
  yield put({ type: action_type.SET_LOADING, payload: true });

  yield axios
    .get(API_URL + `organisation/dashboard/`, AUTH_HEADERS())
    .then((resp) => {
      data.response = true;
      data.backend_data = resp.data;
    })
    .catch((error) => {
      console.log(error, "error");
    });

  yield put({ type: action_type.SET_ORG_DASHBOARD_DATA, data });
  yield put({ type: action_type.SET_LOADING, payload: false });
}

export function* agnDashboardData(payload) {
  let data = { response: false, backend_data: null };
  yield put({ type: action_type.SET_LOADING, payload: true });

  let url = ``;
  if (payload?.data?.isUrl === "lawyer") {
    url = `lawyer/dashboard/`;
  } else {
    url = `agency/dashboard/`;
  }

  yield axios
    .get(API_URL + url, AUTH_HEADERS())
    .then((resp) => {
      data.response = true;
      data.backend_data = resp.data;
    })
    .catch((error) => {
      console.log(error, "error");
    });

  yield put({ type: action_type.SET_AGN_DASHBOARD_DATA, data });
  yield put({ type: action_type.SET_LOADING, payload: false });
}

export function* resDashboardData() {
  let data = { response: false, backend_data: null };
  yield put({ type: action_type.SET_LOADING, payload: true });

  yield axios
    .get(API_URL + `resources/dashboard/`, AUTH_HEADERS())
    .then((resp) => {
      data.response = true;
      data.backend_data = resp.data;
    })
    .catch((error) => {
      console.log(error, "error");
    });

  yield put({ type: action_type.SET_RES_DASHBOARD_DATA, data });
  yield put({ type: action_type.SET_LOADING, payload: false });
}

export function* expiringSowMsaData(payload) {
  let data = { response: false, backend_data: null };
  yield put({ type: action_type.SET_LOADING, payload: true });

  yield axios
    .get(payload?.payload, AUTH_HEADERS())
    .then((resp) => {
      data.response = true;
      data.backend_data = resp.data;
    })
    .catch((error) => {
      console.log(error, "error");
    });

  yield put({ type: action_type.SET_EXPIRING_SOW_MSA_DATA, data });
  yield put({ type: action_type.SET_LOADING, payload: false });
}

export function* revenueExpenseData(payload) {
  let data = { response: false, backend_data: null };
  yield put({ type: action_type.SET_LOADING, payload: true });

  yield axios
    .get(payload?.payload, AUTH_HEADERS())
    .then((resp) => {
      data.response = true;
      data.backend_data = resp.data;
    })
    .catch((error) => {
      console.log(error, "error");
    });

  yield put({ type: action_type.SET_REVENUE_EXPENSCE_DATA, data });
  yield put({ type: action_type.SET_LOADING, payload: false });
}

export function* agencyStatisticDashData(payload) {
  let data = { response: false, backend_data: null };
  yield put({ type: action_type.SET_LOADING, payload: true });

  yield axios
    .get(payload?.payload, AUTH_HEADERS())
    .then((resp) => {
      data.response = true;
      data.backend_data = resp.data;
    })
    .catch((error) => {
      console.log(error, "error");
    });

  yield put({ type: action_type.SET_STATISTICS_DASHBOARD_DATA, data });
  yield put({ type: action_type.SET_LOADING, payload: false });
}

export function* totalSpendData(payload) {
  let data = { response: false, backend_data: null };
  yield put({ type: action_type.SET_LOADING, payload: true });

  yield axios
    .get(payload?.payload, AUTH_HEADERS())
    .then((resp) => {
      data.response = true;
      data.backend_data = resp.data;
    })
    .catch((error) => {
      console.log(error, "error");
    });

  yield put({ type: action_type.SET_TOTAL_SPEND_DATA, data });
  yield put({ type: action_type.SET_LOADING, payload: false });
}

export function* resourceTotalEarningsData(payload) {
  let data = { response: false, backend_data: null };
  yield put({ type: action_type.SET_LOADING, payload: true });

  yield axios
    .get(payload?.payload, AUTH_HEADERS())
    .then((resp) => {
      data.response = true;
      data.backend_data = resp.data;
    })
    .catch((error) => {
      console.log(error, "error");
    });

  yield put({ type: action_type.SET_RESOURCE_TOTAL_EARNINGS_DATA, data });
  yield put({ type: action_type.SET_LOADING, payload: false });
}
