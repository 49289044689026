import * as action_type from "../../../../Store/Constant";

export const singleUserRatingsReducer = (data = {response: false, backend_data: null }, action) => {
  switch (action.type) {
    case action_type.SET_SINGLE_USER_RATING_REVIEW:
      return action.data;
    default:
      return data;
  }
};

export const getDetailsEditReveiwReducer = (data = {response: false, backend_data: null }, action) => {
  switch (action.type) {
    case action_type.SET_EDIT_DETAILS_RATING_REVIEW:
      return action.data;
    default:
      return data;
  }
};