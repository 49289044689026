import * as action_type from "../../../Store/Constant";

export const loginData = (data = { loading: true }, action) => {
  switch (action.type) {
    case action_type.SET_CHECK_LOGIN:
      return action.data;
    default:
      return data;
  }
};

export const signupReducer = (
  data = { loading: false, redirect: true },
  action
) => {
  switch (action.type) {
    case action_type.SET_SIGNUP:
      return action.data;
    default:
      return data;
  }
};

export const countryReducer = (
  data = {
    response: false,
    backend_data: [],
    state_list: [],
  },
  action
) => {
  switch (action.type) {
    case action_type.SET_COUNTRY:
      return action.data;
    default:
      return data;
  }
};

export const navigateReducer = (
  data = { loading: false, redirect: true },
  action
) => {
  switch (action.type) {
    case action_type.GET_NAVIGATE:
      if (action.data) {
        return action.data;
      }
      return action;
    default:
      return data;
  }
};

export const loadingReducer = (data = false, action) => {
  switch (action.type) {
    case action_type.SET_LOADING:
      return action.payload;

    default:
      return data;
  }
};

export const skillsReducer = (data = [], action) => {
  switch (action.type) {
    case action_type.SET_SKILL_LIST:
      if (action.data.response) {
        return action.data.backend_data;
      }
      return [];

    default:
      return data;
  }
};

export const currencyDataReducer = (data = [], action) => {
  switch (action.type) {
    case action_type.SET_ALL_CURRENCY_LIST:
      if (action.data.response) {
        return action.data.backend_data;
      }
      return [];

    default:
      return data;
  }
};
