import { put } from 'redux-saga/effects'
import * as action_type from '../../../../Store/Constant';
import { AUTH_MULTYPART_HEADERS, API_URL, AUTH_HEADERS, LIMIT_DATA } from '../../../../Axios/axiosData'
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

export function* resWorkAvailabilityData() {

    let data = { response: false, backend_data: null }
    yield put({ type: action_type.SET_LOADING, payload: true })

    yield axios.get(API_URL + `resources/work-preference/`, AUTH_HEADERS())
        .then(resp => {
            data.response = true
            data.backend_data = resp.data

        }).catch((error) => {
            console.log(error, "error")
        })

    yield put({ type: action_type.SET_LOADING, payload: false })
    yield put({ type: action_type.SET_WORK_AVAILABILITY, data })

}

export function* resCreateWorkPreferenceData(payload) {

    let data = { response: false, backend_data: null }
    yield put({ type: action_type.SET_LOADING, payload: true })

    yield axios.post(API_URL + `resources/work-preference/`, payload?.payload, AUTH_HEADERS())
        .then(resp => {
            data.response = true
            data.backend_data = resp.data

        }).catch((error) => {
            console.log(error, "error")
        })

    yield axios.get(API_URL + `resources/work-preference/`, AUTH_HEADERS())
        .then(resp => {
            data.response = true
            data.backend_data = resp.data

        }).catch((error) => {
            console.log(error, "error")
        })

    yield put({ type: action_type.SET_LOADING, payload: false })
    yield put({ type: action_type.SET_WORK_AVAILABILITY, data })

}

export function* resUserProfileData() {

    let data = { response: false, backend_data: null }
    yield put({ type: action_type.SET_LOADING, payload: true })

    yield axios.get(API_URL + `accounts/user-profile/`, AUTH_HEADERS())
        .then(resp => {
            data.response = true
            data.backend_data = resp.data

        }).catch((error) => {
            console.log(error, "error")
        })

    yield put({ type: action_type.SET_LOADING, payload: false })
    yield put({ type: action_type.SET_USER_PROFILE_DETAILS_DATA, data })

}

export function* resUpdateWorkPreferenceData(payload) {

    let data = { response: false, backend_data: null }
    yield put({ type: action_type.SET_LOADING, payload: true })

    yield axios.patch(API_URL + `resources/work-preference/${payload?.payload?.id}/`, payload?.payload, AUTH_HEADERS())
        .then(resp => {
            data.response = true
            data.backend_data = resp.data

        }).catch((error) => {
            console.log(error, "error")
        })

    yield axios.get(API_URL + `resources/work-preference/`, AUTH_HEADERS())
        .then(resp => {
            data.response = true
            data.backend_data = resp.data

        }).catch((error) => {
            console.log(error, "error")
        })

    yield put({ type: action_type.SET_LOADING, payload: false })
    yield put({ type: action_type.SET_WORK_AVAILABILITY, data })

}