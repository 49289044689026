import { takeEvery } from "redux-saga/effects";
import * as action_type from "./Constant";
import {
  msaFieldSaga,
  getAddMsaSaga,
  getEditMsa,
  getRsoureListSaga,
  getAgencyListSaga,
  approvalAllUsersData,
} from "../Components/Dashboard/Organisation/Redux/CheckMsaSaga";

import {
  orgRoleListSage,
  orgRoleDetailSaga,
  editOrgRoleSaga,
} from "../Components/Dashboard/Organisation/Redux/RoleSage";

import {
  signupSaga,
  loginSaga,
  getOtpSaga,
  countrySaga,
  skillsSaga,
  google_key_get_saga,
  google_key_login_saga,
  currencyListData,
  linkedinLogin,
  google_Access_Token_saga,
} from "../Components/Home/Redux/CheckLoginSaga";

import {
  orgProfileSaga,
  showListSaga,
  allMsaListSaga,
  msaDetailSaga,
  sowDetailSaga,
  sowFieldSaga,
  updateSowSaga,
  msaDynamicVariables,
  allMsaFiltersListSaga,
  allOrgListSaga,
  OrgDetailsByIdSaga,
} from "../Components/Dashboard/Organisation/Redux/organisationSaga";

import {
  getCreateInviteResource,
  viewMyTeamData,
  getPostContract,
  getAnalyzeByAI,
  getUserResetPassword,
  getResourceReserveRequest,
  getResourceAcceptReserveRequest,
} from "../Components/Dashboard/Organisation/Redux/inviteResourceSaga";

import {
  agencyProfileSaga,
  lawyerProfileSaga,
} from "../Components/Dashboard/Agency/Redux/agencySaga";

import {
  orgResourceSaga,
  resInitialProfileData,
} from "../Components/Dashboard/Resource/Redux/resourceSaga";

import {
  sendEmailForgot,
  verifyForgotOTP,
} from "../Components/Dashboard/Organisation/Redux/forgotSaga";

import {
  postDataRatingsReview,
  getSingleUserRatingsReview,
  deleteSigleUsersRating,
  getEditDataRatingsReview,
  editRatingsReview,
} from "../Components/Dashboard/Organisation/Redux/RatingsSaga";
import {
  msaListForExcel,
  sowListForExcel,
  timesheetListForExcel,
  invoiceListForExcel,
  roleListForExcel,
  usersListForExcel,
} from "../Components/Dashboard/Organisation/Redux/ExcelSaga";
import {
  getJobPostListData,
  addNewJobPost,
  getLanguageListData,
  editJobPostData,
  addQuestionsData,
  editQuestionData,
  deleteQuestionData,
  singleJobPostData,
  addNewSkillsData,
  applicationListData,
  applicationChangeStatus,
  getExperienceLevelData,
  addExperienceLevelData,
  deleteJobPostData,
} from "../Components/Dashboard/Organisation/Redux/JobPostSaga";

import {
  getSettingsCompanyList,
  getBusinessUnitList,
  getCostCenterList,
  getGlAccountList,
  getUnpscCodeList,
  getTaxGroupList,
  getServiceTaxList,
  getPaymentTermList,
  getMsaTypeList,
  getSowTypeList,
  getAddCompanyLocation,
  getAddBusinessUnit,
  getAddCostCenter,
  getAddGlAccounts,
  getAddMsaType,
  getAddUnpscCode,
  getAddTaxGroup,
  getAddTaxService,
  getAddPaymentTerm,
  getAddSowType,
  editUnpscCodeData,
  editTaxGroupData,
  editTaxServiceRateData,
  editCostCenterData,
  editGlAccountsData,
  editPaymentTermData,
  editMsaTypeData,
  editSowTypeData,
  editBusinessUnitData,
  editCompanyLocationData,
  getExperienceCategoryList,
  getAddExpenseCategory,
  editExpenseCategory,
  deleteExpenseCategoryData,
  getIncomeTaxSlabs,
  getPrivacySettingsList,
  updatePrivacySetting,
} from "../Components/Dashboard/Organisation/Redux/SettingSaga";

import {
  jobsFiltersData,
  profileFiltesData,
  applyForJobData,
  latestsJobsData,
  updateApplyForJobData,
} from "../Components/Dashboard/Organisation/Redux/jobProfileSaga";

import {
  getTimesheetListData,
  createTimesheetData,
  viewTimesheetData,
} from "../Components/Dashboard/Organisation/Redux/TimeSheetSaga";

import {
  getInvoiceListData,
  addInvoiceChargesData,
  getInvoiceChargesListData,
  createNewInvoiceData,
  getInvoiceDatailsData,
  getPdfInvoiceData,
  resourceSendInvoiceMailData,
} from "../Components/Dashboard/Organisation/Redux/InvoiceSaga";

import {
  paymentsData,
  paymentsInvoiceDetailsData,
  costOfPaymentsTransferData,
} from "../Components/Dashboard/Organisation/Redux/PaymentsSaga";

import {
  getOrganisationUsersList,
  getAddOrganisationUsers,
  deleteOrganisationUserData,
  getAgencyUsersList,
  getAddAgencyUsers,
  deleteAgencyUserData,
  getOrgUserDetailData,
  getAgnUserDetailData,
} from "../Components/Dashboard/Organisation/Redux/UserSaga";
import {
  getMsaApprovalListData,
  getMsaCallApproveStatus,
  getSowApprovalListData,
  getTimesheetApprovalListData,
  getCallApproveStatus,
  getTimesheetCallApproveStatus,
  getInvoiceApprovalListData,
  getInvoiceCallApproveStatus,
} from "../Components/Dashboard/Organisation/Redux/ApprovalSaga";
import {
  resourceProfileData,
  viewResProfileData,
  viewAgnProfileData,
  getLoginUserProfileData,
} from "../Components/Dashboard/Organisation/Redux/ResProSaga";

import {
  msaFormattedTemplate,
  msaDefaultTemplate,
  allMsaAgreementTemplate,
  deleteMSaTemplates,
  msaAgreementTemplateById,
  createMsaAgreementTemplate,
  editMsaAgreementTemplate,
  editMsaDefaultAgreementTemplate,
  sendMsaAgreementTemplate,
  uploadMsaAgreementMedia,
  downloadPDFmsafn,
  docTypeFileData,
  downloadPDFsowfn,
  changeDraftPrintDownloadData,
  sowFormattedTemplateData,
  allSowTemplateListData,
  changeSowTemplateData,
} from "./agreementTemplate/agreementTemplateSaga";

import {
  orgDashboardData,
  agnDashboardData,
  resDashboardData,
  expiringSowMsaData,
  revenueExpenseData,
  agencyStatisticDashData,
  totalSpendData,
  resourceTotalEarningsData,
} from "../Components/Dashboard/Organisation/Redux/DashBoardSaga";

import {
  resWorkAvailabilityData,
  resCreateWorkPreferenceData,
  resUserProfileData,
  resUpdateWorkPreferenceData,
} from "../Components/Dashboard/Organisation/Redux/AvailabilitySaga";
import {
  accepRejectConnectReqSaga,
  downloadGoogleDocxAiTemplateSaga,
  cancelledPlanSaga,
  commonRequestSaga,
  deleteESignDocsSaga,
  deleteNotificationReqSaga,
  downloadDocxAiTemplateSaga,
  downloadPdfAiTemplateSaga,
  getAllInvoiceSaga,
  getAllNotificationSaga,
  getAllPlanSaga,
  getAlleSignDocsSaga,
  getCreateInvoiceSaga,
  getMasterDataAllSaga,
  purchasePlanSaga,
  saveAiTemplateSaga,
  sendeSignDocsSaga,
  updateAiTemplateSaga,
  updateESignDocsSaga,
  updateNotificationSaga,
  userCurrentPlanSaga,
  fetchAiDraftTemplatesSaga,
} from "./CommonSaga";

function* RootSaga() {
  yield takeEvery(action_type.GET_SIGNUP, signupSaga);

  yield takeEvery(action_type.GET_LOGIN, loginSaga);

  yield takeEvery(action_type.GET_MSA_FIELD, msaFieldSaga);

  yield takeEvery(action_type.GET_APPROVAL_ALL_USERS, approvalAllUsersData);

  yield takeEvery(action_type.GET_RESOURCELIST, getRsoureListSaga);

  yield takeEvery(action_type.GET_OTP, getOtpSaga);

  yield takeEvery(action_type.GET_ADDMSA, getAddMsaSaga);

  yield takeEvery(action_type.GET_COUNTRY, countrySaga);

  yield takeEvery(action_type.GET_ALL_CURRENCY_LIST, currencyListData);

  yield takeEvery(action_type.GET_ORG_PROFILE, orgProfileSaga);

  yield takeEvery(action_type.GET_AGENCY_PROFILE, agencyProfileSaga);

  yield takeEvery(action_type.GET_LAWYER_PROFILE, lawyerProfileSaga);

  yield takeEvery(action_type.GET_SKILL_LIST, skillsSaga);

  yield takeEvery(action_type.GET_RESOURCE_PROFILE, orgResourceSaga);

  yield takeEvery(action_type.GET_SHOW_LIST, showListSaga);

  yield takeEvery(action_type.GET_SOW_DETAIL, sowDetailSaga);

  yield takeEvery(action_type.GET_SOW_FIELD, sowFieldSaga);

  yield takeEvery(action_type.UPDATE_SOW_DETAIL, updateSowSaga);

  //MSA ALL SAGA POINT

  yield takeEvery(action_type.GET_MSA_LIST, allMsaListSaga);

  yield takeEvery(action_type.GET_ORGANISATION_LIST, allOrgListSaga);

  yield takeEvery(action_type.GET_ORG_BY_ID, OrgDetailsByIdSaga);

  yield takeEvery(action_type.GET_MSA_FILTERS_LIST, allMsaFiltersListSaga);

  yield takeEvery(action_type.GET_MSA_DETAIL, msaDetailSaga);

  yield takeEvery(action_type.GOOGLE_KEY_GET, google_key_get_saga);

  yield takeEvery(action_type.LINKEDIN_LOGIN, linkedinLogin);

  yield takeEvery(action_type.GOOGLE_LOGIN, google_key_login_saga);

  //

  yield takeEvery(action_type.GET_AGENCY_PROFILE_LIST, getAgencyListSaga);
  yield takeEvery(action_type.GET_ORG_ROLE_LIST, orgRoleListSage);
  yield takeEvery(action_type.GET_ORG_ROLE_DETAIL, orgRoleDetailSaga);

  yield takeEvery(action_type.EDIT_ORG_ROLE, editOrgRoleSaga);

  // Settings Component Api //

  yield takeEvery(
    action_type.GET_SETTINGS_COMPANY_LIST,
    getSettingsCompanyList
  );

  yield takeEvery(action_type.GET_BUSINESS_UNIT_LIST, getBusinessUnitList);

  yield takeEvery(action_type.GET_COST_CENTER_LIST, getCostCenterList);

  yield takeEvery(action_type.GET_GL_ACCOUNT_LIST, getGlAccountList);

  yield takeEvery(action_type.GET_UNPSC_CODE_LIST, getUnpscCodeList);

  yield takeEvery(action_type.GET_TAX_GROUP_LIST, getTaxGroupList);

  yield takeEvery(
    action_type.GET_EXPERIENCE_CATEGORY_LIST,
    getExperienceCategoryList
  );

  yield takeEvery(action_type.GET_TAX_SERVICE_LIST, getServiceTaxList);

  yield takeEvery(action_type.GET_PAYMENT_TERM_LIST, getPaymentTermList);

  yield takeEvery(action_type.GET_MSA_TYPE_LIST, getMsaTypeList);

  yield takeEvery(action_type.GET_SOW_TYPE_LIST, getSowTypeList);

  yield takeEvery(action_type.GET_INCOME_TAX_SLABS_LIST, getIncomeTaxSlabs);

  yield takeEvery(
    action_type.GET_PRIVACY_SETTINGS_LIST,
    getPrivacySettingsList
  );

  yield takeEvery(
    action_type.UPDATE_PRIVACY_SETTINGS_LIST,
    updatePrivacySetting
  );

  yield takeEvery(action_type.GET_ADD_COMPANY_LOCATION, getAddCompanyLocation);

  yield takeEvery(action_type.GET_ADD_BUSINESS_UNIT_LIST, getAddBusinessUnit);

  yield takeEvery(action_type.GET_ADD_COST_CENTER_LIST, getAddCostCenter);

  yield takeEvery(action_type.GET_ADD_GL_ACCOUNTS_LIST, getAddGlAccounts);

  yield takeEvery(action_type.GET_ADD_MSA_TYPE, getAddMsaType);

  yield takeEvery(action_type.GET_ADD_UNPSC_CODE_TYPE, getAddUnpscCode);

  yield takeEvery(action_type.GET_ADD_TAX_GROUP, getAddTaxGroup);

  yield takeEvery(action_type.GET_ADD_EXPENSE_CATEGORY, getAddExpenseCategory);

  yield takeEvery(action_type.GET_ADD_TAX_SERVICE, getAddTaxService);

  yield takeEvery(action_type.GET_ADD_PAYMENT_TERM, getAddPaymentTerm);

  yield takeEvery(action_type.GET_ADD_SOW_TYPE, getAddSowType);

  // Organisation Users

  yield takeEvery(
    action_type.GET_ORGANISATION_USERS_LIST,
    getOrganisationUsersList
  );

  yield takeEvery(
    action_type.GET_ADD_ORGANISATION_USERS,
    getAddOrganisationUsers
  );

  yield takeEvery(
    action_type.DELETE_ORGANISATION_USER,
    deleteOrganisationUserData
  );

  // Agency Users

  yield takeEvery(action_type.GET_AGENCY_USERS_LIST, getAgencyUsersList);

  yield takeEvery(action_type.GET_ADD_AGENCY_USERS, getAddAgencyUsers);

  yield takeEvery(
    action_type.CREATE_INVITE_RESOURCE_USERS,
    getCreateInviteResource
  );

  yield takeEvery(action_type.GET_RESERVE_REQUEST, getResourceReserveRequest);

  yield takeEvery(
    action_type.GET_ACCEPT_RESERVE_REQUEST,
    getResourceAcceptReserveRequest
  );

  yield takeEvery(action_type.GET_RESET_PASSWORD, getUserResetPassword);

  yield takeEvery(action_type.GET_ANALYZE_CONTRACT, getPostContract);
  yield takeEvery(action_type.GET_ANALYZE_BY_AI, getAnalyzeByAI);

  yield takeEvery(action_type.DELETE_AGENCY_USER, deleteAgencyUserData);

  // Organisation Approval

  yield takeEvery(action_type.GET_MSA_APPROVAL_LIST, getMsaApprovalListData);

  yield takeEvery(action_type.GET_SOW_APPROVAL_LIST, getSowApprovalListData);

  yield takeEvery(
    action_type.GET_TIMESHEET_APPROVAL_LIST,
    getTimesheetApprovalListData
  );

  yield takeEvery(
    action_type.GET_INVOICE_APPROVAL_LIST,
    getInvoiceApprovalListData
  );

  yield takeEvery(action_type.CALL_APPROVE_STATUS, getCallApproveStatus);

  yield takeEvery(action_type.MSA_CALL_APPROVE_STATUS, getMsaCallApproveStatus);

  yield takeEvery(
    action_type.TIMESHEET_CALL_APPROVE_STATUS,
    getTimesheetCallApproveStatus
  );

  yield takeEvery(
    action_type.INVOICE_CALL_APPROVE_STATUS,
    getInvoiceCallApproveStatus
  );

  yield takeEvery(action_type.GET_EDIT_MSA, getEditMsa);

  //settings edit //

  yield takeEvery(action_type.GET_EDIT_UNPSC_CODE, editUnpscCodeData);

  yield takeEvery(action_type.GET_EDIT_TAX_GROUP, editTaxGroupData);

  yield takeEvery(action_type.GET_EDIT_EXPENSE_CATEGORY, editExpenseCategory);

  yield takeEvery(
    action_type.GET_EDIT_TAX_SERVICE_RATE,
    editTaxServiceRateData
  );

  yield takeEvery(action_type.GET_EDIT_COST_CENTER, editCostCenterData);

  yield takeEvery(action_type.GET_EDIT_GL_ACCOUNTS, editGlAccountsData);

  yield takeEvery(action_type.GET_EDIT_PAYMENT_TERM, editPaymentTermData);

  yield takeEvery(action_type.GET_EDIT_MSA_TYPE, editMsaTypeData);

  yield takeEvery(action_type.GET_EDIT_SOW_TYPE, editSowTypeData);

  yield takeEvery(action_type.GET_EDIT_BUSINESS_UNIT, editBusinessUnitData);

  yield takeEvery(
    action_type.GET_EDIT_COMPANY_LOCATION,
    editCompanyLocationData
  );

  // detete settings

  yield takeEvery(
    action_type.DELETE_EXPENSES_CATEGORY,
    deleteExpenseCategoryData
  );

  // resource profile //

  yield takeEvery(action_type.GET_RESOURCE_PROFILE_LIST, resourceProfileData);

  yield takeEvery(action_type.GET_VIEW_RES_PROFILE, viewResProfileData);

  yield takeEvery(action_type.GET_MY_TEAM_USER_DETAILS, viewMyTeamData);

  yield takeEvery(
    action_type.GET_LOGIN_USER_PROFILE_DETAILS,
    getLoginUserProfileData
  );

  yield takeEvery(action_type.GET_VIEW_AGN_PROFILE, viewAgnProfileData);

  // time sheet //

  yield takeEvery(action_type.GET_TIMESHEET_LIST, getTimesheetListData);

  yield takeEvery(action_type.SEND_APPROVAL_TIMESHEET, createTimesheetData);

  yield takeEvery(action_type.GET_VIEW_TIMESHEET_DETAILS, viewTimesheetData);

  yield takeEvery(action_type.GET_MSA_DYNAMIC_VARIABLES, msaDynamicVariables);

  // agreement template //

  yield takeEvery(
    action_type.GET_ALL_MSA_AGREEMENT_TEMPLATE,
    allMsaAgreementTemplate
  );

  yield takeEvery(action_type.DELETE_AGREEMENT_TEMPLATE, deleteMSaTemplates);

  yield takeEvery(
    action_type.GET_MSA_AGREEMENT_TEMPLATE_BY_ID,
    msaAgreementTemplateById
  );

  yield takeEvery(action_type.GET_DEFAULT_TEMPLATE, msaDefaultTemplate);

  yield takeEvery(action_type.GET_FORMATTED_TEMPLATE, msaFormattedTemplate);

  yield takeEvery(
    action_type.CREATE_MSA_AGREEMENT_TEMPLATE,
    createMsaAgreementTemplate
  );

  yield takeEvery(
    action_type.EDIT_MSA_AGREEMENT_TEMPLATE,
    editMsaAgreementTemplate
  );

  yield takeEvery(
    action_type.EDIT_MSA_DEFAULT_AGREEMENT_TEMPLATE,
    editMsaDefaultAgreementTemplate
  );

  yield takeEvery(
    action_type.SEND_MSA_AGREEMENT_TEMPLATE,
    sendMsaAgreementTemplate
  );
  yield takeEvery(
    action_type.UPLOAD_MSA_AGREEMENT_MEDIA,
    uploadMsaAgreementMedia
  );

  // sow template

  yield takeEvery(
    action_type.GET_SOW_FORMATTED_TEMPLATE,
    sowFormattedTemplateData
  );

  yield takeEvery(action_type.GET_ALL_SOW_TEMPLATE, allSowTemplateListData);

  yield takeEvery(action_type.GET_SOW_TEMPLATE_CHANGE, changeSowTemplateData);

  // invoice management

  yield takeEvery(action_type.GET_INVOICE_LIST, getInvoiceListData);

  yield takeEvery(
    action_type.GET_INVOICE_CHARGES_LIST,
    getInvoiceChargesListData
  );

  yield takeEvery(action_type.ADD_INVOICE_CHARGES, addInvoiceChargesData);

  yield takeEvery(action_type.CREATE_NEW_INVOICE, createNewInvoiceData);

  yield takeEvery(action_type.GET_INVOICE_DETAILS_DATA, getInvoiceDatailsData);

  yield takeEvery(
    action_type.RESOURCE_SEND_INVOICE_MAIL,
    resourceSendInvoiceMailData
  );

  yield takeEvery(action_type.GET_INVOICE_PDF_DATA, getPdfInvoiceData);

  yield takeEvery(action_type.POST_PAYMENTS_DATA, paymentsData);

  yield takeEvery(
    action_type.GET_COST_OF_PAYMENTS_TRANSFER,
    costOfPaymentsTransferData
  );

  yield takeEvery(
    action_type.GET_PAYMENTS_INVOICE_DETAILS,
    paymentsInvoiceDetailsData
  );

  // Job Posting

  yield takeEvery(action_type.GET_JOB_POST_LIST, getJobPostListData);

  yield takeEvery(action_type.DELETE_JOB_POST, deleteJobPostData);

  yield takeEvery(action_type.ADD_JOB_POST, addNewJobPost);

  yield takeEvery(action_type.GET_JOB_POST_DETAILS, singleJobPostData);

  yield takeEvery(action_type.EDIT_JOB_POST, editJobPostData);

  yield takeEvery(action_type.ADD_NEW_QUESTION, addQuestionsData);

  yield takeEvery(action_type.EDIT_QUESTION_JOB_POST, editQuestionData);

  yield takeEvery(action_type.DELETE_QUESTION, deleteQuestionData);

  yield takeEvery(action_type.GET_APPLICATION_LIST_DATA, applicationListData);

  yield takeEvery(
    action_type.APPLICATION_STATUS_CHANGE,
    applicationChangeStatus
  );

  yield takeEvery(action_type.GET_LANGUAGE_LIST, getLanguageListData);

  yield takeEvery(
    action_type.GET_EXPERIENCE_LEVEL_LIST,
    getExperienceLevelData
  );

  yield takeEvery(
    action_type.ADD_EXPERIENCE_LEVEL_LIST,
    addExperienceLevelData
  );

  yield takeEvery(action_type.GET_ORG_USER_DETAILS_DATA, getOrgUserDetailData);

  yield takeEvery(action_type.GET_AGN_USER_DETAILS_DATA, getAgnUserDetailData);

  yield takeEvery(action_type.ADD_NEW_SKILLS, addNewSkillsData);

  yield takeEvery(action_type.GET_ORG_DASHBOARD_DATA, orgDashboardData);

  yield takeEvery(action_type.GET_AGN_DASHBOARD_DATA, agnDashboardData);

  yield takeEvery(action_type.GET_RES_DASHBOARD_DATA, resDashboardData);

  yield takeEvery(action_type.GET_EXPIRING_SOW_MSA_DATA, expiringSowMsaData);

  yield takeEvery(action_type.GET_REVENUE_EXPENSCE_DATA, revenueExpenseData);

  yield takeEvery(
    action_type.GET_STATISTICS_DASHBOARD_DATA,
    agencyStatisticDashData
  );

  yield takeEvery(action_type.GET_TOTAL_SPEND_DATA, totalSpendData);

  yield takeEvery(
    action_type.GET_RESOURCE_TOTAL_EARNINGS_DATA,
    resourceTotalEarningsData
  );

  // resource work availability

  yield takeEvery(action_type.GET_WORK_AVAILABILITY, resWorkAvailabilityData);

  yield takeEvery(
    action_type.CREATE_WORK_PREFERENCE,
    resCreateWorkPreferenceData
  );

  yield takeEvery(
    action_type.UPDATE_WORK_PREFERENCE,
    resUpdateWorkPreferenceData
  );

  yield takeEvery(
    action_type.GET_USER_PROFILE_DETAILS_DATA,
    resUserProfileData
  );

  /////////////// New Onboarding Saga action ////////////////

  yield takeEvery(action_type.GET_RES_INITIAL_PROFILE, resInitialProfileData);

  // jobs & filters data

  yield takeEvery(action_type.GET_JOBS_FILTERS_DATA, jobsFiltersData);

  yield takeEvery(action_type.GET_LATEST_JOBS_DATA, latestsJobsData);

  yield takeEvery(action_type.GET_PROFILES_FILTERS_DATA, profileFiltesData);

  yield takeEvery(action_type.APPLY_FOR_JOB_DATA, applyForJobData);

  yield takeEvery(action_type.UPDATE_APPLY_FOR_JOB_DATA, updateApplyForJobData);

  // msa pdf

  yield takeEvery(action_type.GET_DOWNLOAD_MSA_PDF_DATA, downloadPDFmsafn);

  yield takeEvery(action_type.GET_DOC_TYPE_CONTENT, docTypeFileData);

  yield takeEvery(
    action_type.CHANGE_DRAFT_PRINT_DOWNLOAD_DATA,
    changeDraftPrintDownloadData
  );

  yield takeEvery(action_type.GET_DOWNLOAD_SOW_PDF_DATA, downloadPDFsowfn);

  yield takeEvery(action_type.FORGOT_SEND_EMAIL, sendEmailForgot);

  yield takeEvery(action_type.VERITY_FORGOT_OTP, verifyForgotOTP);

  // ratings

  yield takeEvery(action_type.POST_RATINGS_REVIEW_DATA, postDataRatingsReview);

  yield takeEvery(action_type.EDIT_RATINGS_REVIEW_DATA, editRatingsReview);

  yield takeEvery(
    action_type.GET_EDIT_DETAILS_RATING_REVIEW,
    getEditDataRatingsReview
  );

  yield takeEvery(
    action_type.GET_SINGLE_USER_RATING_REVIEW,
    getSingleUserRatingsReview
  );

  yield takeEvery(
    action_type.USER_REVIEW_RATING_DELETE,
    deleteSigleUsersRating
  );

  // excel data

  yield takeEvery(action_type.GET_MSA_DATA_EXCEL, msaListForExcel);

  yield takeEvery(action_type.GET_SOW_DATA_EXCEL, sowListForExcel);

  yield takeEvery(action_type.GET_TIMESHEET_DATA_EXCEL, timesheetListForExcel);

  yield takeEvery(action_type.GET_INVOICE_DATA_EXCEL, invoiceListForExcel);

  yield takeEvery(action_type.GET_ROLE_DATA_EXCEL, roleListForExcel);

  yield takeEvery(action_type.GET_USERS_DATA_EXCEL, usersListForExcel);

  yield takeEvery(action_type.GET_COMMON_REQ, commonRequestSaga);

  yield takeEvery(action_type.GET_NOTIFICATION_REQ, getAllNotificationSaga);

  yield takeEvery(
    action_type.GET_UPDATE_NOTIFICATION_REQ,
    updateNotificationSaga
  );

  yield takeEvery(
    action_type.GET_DELETE_CONNECTION_REQ,
    deleteNotificationReqSaga
  );

  yield takeEvery(
    action_type.GET_ACCEPT_REJECT_CONNECTION_REQ,
    accepRejectConnectReqSaga
  );

  yield takeEvery(action_type.GET_ALL_PLANS, getAllPlanSaga);

  yield takeEvery(action_type.GET_PURCHASE_PLAN, purchasePlanSaga);

  yield takeEvery(action_type.GET_CURRENT_PLAN, userCurrentPlanSaga);

  yield takeEvery(action_type.GET_CANCEL_PLAN, cancelledPlanSaga);

  yield takeEvery(action_type.GET_SAVE_AI_TEMPLATE, saveAiTemplateSaga);

  yield takeEvery(
    action_type.GET_DOWNLOAD_AI_TEMPLATE_PDF,
    downloadPdfAiTemplateSaga
  );

  yield takeEvery(
    action_type.GET_DOWNLOAD_AI_TEMPLATE_DOCX,
    downloadDocxAiTemplateSaga
  );

  yield takeEvery(action_type.GET_UPDATE_AI_TEMPLATE, updateAiTemplateSaga);

  yield takeEvery(action_type.GET_SEND_ESIGN_DOCS, sendeSignDocsSaga);

  yield takeEvery(action_type.GET_ALL_ESIGN_DOCS, getAlleSignDocsSaga);

  yield takeEvery(action_type.GET_UPDATE_ESIGN_DOCS, updateESignDocsSaga);

  yield takeEvery(action_type.GET_DELTEE_ESIGN_DOCS, deleteESignDocsSaga);

  yield takeEvery(action_type.GET_INVOICES, getAllInvoiceSaga);

  yield takeEvery(action_type.GET_CREATE_INVOICES, getCreateInvoiceSaga);

  yield takeEvery(action_type.GET_MASTERDATARESOURCE_ALL, getMasterDataAllSaga);

  yield takeEvery(
    action_type.GET_DOWNLOAD_AI_TEMPLATE_DOCX,
    downloadDocxAiTemplateSaga
  );

  yield takeEvery(
    action_type.GET_DOWNLOAD_AI_TEMPLATE_GOOGLE_DOCX,
    downloadGoogleDocxAiTemplateSaga
  );
  yield takeEvery(
    action_type.GET_GOOGLE_ACCESS_TOKEN,
    google_Access_Token_saga
  );
  yield takeEvery(
    action_type.GET_FETCH_AI_DRAFT_TEMPLATES_REQUEST,
    fetchAiDraftTemplatesSaga
  );
}

export default RootSaga;
