import { call, put } from "redux-saga/effects";
import * as action_type from "../../../../Store/Constant";
import {
  AUTH_MULTYPART_HEADERS,
  API_URL,
  AUTH_HEADERS,
  LIMIT_DATA,
} from "../../../../Axios/axiosData";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

const DUMMY_SOW = () => {
  const profile_data = JSON.parse(localStorage.getItem("user_data"));
  return {
    msa: null,
    msa_detail: null,
    title: "",
    sow_number: "",
    sow_type: null,
    sow_type_detail: null,
    start_date: "",
    end_date: "",
    resource: null,
    resource_detail: null,
    is_day: true,
    is_hour: false,
    currency: profile_data.profile.currency,
    currency_detail: profile_data.profile.currency_detail,
    work_rate: "",
    work_quantity: "",
    amount: "",
    tax_group: null,
    tax_group_detail: null,
    tax_percent: "",
    account: null,
    account_detail: null,
    cost_center: null,
    cost_center_detail: null,
    attachments: [],
    attachments_data: [],
    attachment_files: [],
    description: "",
    comments: "",
    status: "pending_approval",
    // costing for organisation
    currency_org: null,
    currency_org_detail: {},
    work_rate_org: "",
    work_quantity_org: "",
    amount_org: "",
    tax_group_org: "",
    tax_group_org_detail: null,
    tax_percent_org: "",
    account_org: null,
    account_org_detail: null,
    cost_center_org: null,
    cost_center_org_detail: null,
    grand_total: null,
    grand_total_org: null,
    approver: "",
    resource_currency_conversion: "",
    org_currency_conversion: "",
    margin_amount: "",
    margin_percent: "",
  };
};

export function* orgProfileSaga({ payload }) {
  yield axios
    .post(API_URL + "organisation/profile/", payload, AUTH_MULTYPART_HEADERS())
    .then((resp) => {
      if (resp.data.status < 205) {
        let user_data = JSON.parse(localStorage.getItem("user_data"));
        user_data.profile = resp.data.profile;
        localStorage.setItem("user_data", JSON.stringify(user_data));
        window.location.reload(".");
      } else {
        toast.error(
          `Failed to submit form with status code ${resp.data.status}`
        );
      }
    })
    .catch((error) => {
      if (error?.response?.data?.company_website) {
        toast.error(
          `${"Website"} : ${error?.response?.data?.company_website[0]}`
        );
      }

      if (error?.response?.data?.company_name) {
        toast.error(`${error?.response?.data?.company_name[0]}`);
      }

      setTimeout(() => {
        window.location.reload(".");
      }, 4500);
    });

  yield put({ type: action_type.SET_LOADING, payload: false });
}
// Speed Up Api Call showListSaga
export function* showListSaga(payload) {
  yield put({ type: action_type.SET_LOADING, payload: true });

  const path = payload?.params
    ? `${API_URL}sow/statement-of-work/?limit=${LIMIT_DATA}${payload?.params}`
    : `${API_URL}sow/statement-of-work/?limit=100`;

  let data = { response: false, backend_data: [] };

  try {
    console.time("SOW API Call"); // Start performance tracking

    // Fetch data using call() for proper saga handling
    const response = yield call(axios.get, path, AUTH_HEADERS());

    console.timeEnd("SOW API Call"); // Log execution time

    data = { response: true, backend_data: response.data };
  } catch (error) {
    console.error("SOW API Error:", error);
  }

  yield put({ type: action_type.SET_SHOW_LIST, data });
  yield put({ type: action_type.SET_LOADING, payload: false });
}
// below Slow Api call of showListSaga

// export function* showListSaga(payload) {

//     yield put({ type: action_type.SET_LOADING, payload: true })

//     let path;

//     if (payload?.params) {
//         path = API_URL + `sow/statement-of-work/?limit=${LIMIT_DATA}${payload?.params}`
//     } else {
//         path = API_URL + `sow/statement-of-work/?limit=100`
//     }

//     let data = { response: false, backend_data: [] }

//     yield axios.get(path, AUTH_HEADERS())
//         .then(resp => {

//             data.response = true
//             data.backend_data = resp.data

//         })

//     yield put({ type: action_type.SET_SHOW_LIST, data })
//     yield put({ type: action_type.SET_LOADING, payload: false })

// export function* showListSaga({ path, params }) {

// yield put({ type: action_type.SET_LOADING, payload: true })
// if (!path) {
//     path = API_URL + 'sow/statement-of-work/?limit=' + LIMIT_DATA + params
// }

// let data = { response: false, backend_data: [] }

// yield axios.get(path, AUTH_HEADERS())
//     .then(resp => {
//         data.response = true
//         data.backend_data = resp.data
//     })

// yield put({ type: action_type.SET_SHOW_LIST, data })
// yield put({ type: action_type.SET_LOADING, payload: false })
//}

export function* sowDetailSaga({ id }) {
  yield put({ type: action_type.SET_LOADING, payload: true });

  let data = { sow: {} };

  if (id == "add") {
    data["sow"] = DUMMY_SOW();
  } else {
    let url = API_URL + "sow/statement-of-work/" + id + "/";
    yield axios
      .get(url, AUTH_HEADERS())
      .then((resp) => {
        data.response = true;
        data.sow = resp.data;

        data.sow.start_date = data.sow.start_date.replace("T", " ");
        data.sow.start_date = data.sow.start_date.replace("Z", "");

        data.sow.end_date = data.sow.end_date.replace("T", " ");
        data.sow.end_date = data.sow.end_date.replace("Z", "");
      })
      .catch((err) => {
        alert(err.message);
      });
  }

  yield put({ type: action_type.SET_LOADING, payload: false });
  yield put({ type: action_type.SET_SOW_DETAIL, data });
}

export function* sowFieldSaga() {
  yield put({ type: action_type.SET_LOADING, payload: true });
  let data = { response: false, backend_data: {} };

  yield axios
    .get(API_URL + "sow/sow-fields/", AUTH_MULTYPART_HEADERS())
    .then((resp) => {
      data.response = true;
      data.backend_data = resp.data.payload;

      for (let i of data.backend_data.account) {
        i.label = i.name;
      }

      for (let i of data.backend_data.tax_group) {
        i.label = i.name;
      }

      for (let i of data.backend_data.cost_center) {
        i.label = i.name;
      }

      for (let i of data.backend_data.sow_type) {
        i.label = i.name;
      }
    });

  yield put({ type: action_type.SET_SOW_FIELD, data });
  yield put({ type: action_type.SET_LOADING, payload: false });
}

export function* updateSowSaga({ id, payload, sow_type, callbackSaga }) {
  let data = { response: false, backend_data: null };
  if (id == "add") {
    if (sow_type === 2) {
      yield axios
        .post(API_URL + "sow/statement-of-work/", payload, AUTH_HEADERS())
        .then((resp) => {
          // toast.success(resp.data.message);
          toast.success(
            "Statement Of Work for " +
              " " +
              resp.data.payload.title +
              " " +
              "Created Successfully"
          );
          data.response = true;
          data.path = resp.data.payload.slug;
          callbackSaga(resp.data);
          // data.path = "/organisation/sow/" + resp.data.payload.slug; // Optionally update path as needed
        })
        .catch((error) => {
          toast.error(`${"Error"} : ${error?.response?.data?.error}`);
          callbackSaga(error);
        });
    } else {
      yield axios
        .post(
          API_URL + "sow/statement-of-work/",
          payload,
          AUTH_MULTYPART_HEADERS()
        )
        .then((resp) => {
          toast.success(
            "Statement Of Work for " +
              " " +
              resp.data.payload.title +
              " " +
              "Created Successfully"
          );
          data.response = true;
          data.path = resp.data.payload.slug;
          // data.path = "/organisation/sow/" + resp.data.payload.slug
        })
        .catch((error) => {
          if (error?.response?.data?.title) {
            toast.error(`${"Title"} : ${error?.response?.data?.title[0]}`);
          }

          if (error?.response?.data?.error) {
            toast.error(`${"Error"} : ${error?.response?.data?.error}`);
          }

          if (error?.response?.data?.resource) {
            toast.error("Please Select Resource");
          }

          if (error?.response?.data?.start_date) {
            toast.error("Invalid Start date ,Please Select !");
          }

          if (error?.response?.data?.end_date) {
            toast.error("Invalid End date ,Please Select !");
          }

          if (error?.response?.data?.description) {
            toast.error(
              `${"Description"} : ${error?.response?.data?.description[0]}`
            );
          }

          if (error?.response?.data?.tax_percent) {
            toast.error(
              `${"Tax %"} : ${error?.response?.data?.tax_percent[0]}`
            );
          }

          if (error?.response?.data?.work_quantity) {
            toast.error(
              `${"Quantity"} : ${error?.response?.data?.work_quantity[0]}`
            );
          }

          if (error?.response?.data?.work_rate) {
            toast.error(
              `${"Work Rate"} : ${error?.response?.data?.work_rate[0]}`
            );
          }

          if (error?.response?.data?.currency_org) {
            toast.error("Select Currency Organisation");
          }

          if (error?.response?.data?.tax_percent_org) {
            toast.error(
              `${"Tax %"} : ${error?.response?.data?.tax_percent_org[0]}`
            );
          }

          if (error?.response?.data?.work_quantity_org) {
            toast.error(
              `${"Quantity"} : ${error?.response?.data?.work_quantity_org[0]}`
            );
          }

          if (error?.response?.data?.work_rate_org) {
            toast.error(
              `${"Work Rate"} : ${error?.response?.data?.work_rate_org[0]}`
            );
          }
        });
    }
  } else if (id != "add" && sow_type === 2) {
    yield axios
      .patch(
        API_URL + "sow/statement-of-work/" + id + "/",
        payload,
        AUTH_HEADERS()
      )
      .then((resp) => {
        // toast.success("Statement Of Work for " + " "+resp.data.payload.title + " "+ "Created Successfully");
        toast.success(
          "Statement Of Work for " +
            " " +
            resp.data.payload.title +
            " " +
            "update Successfully"
        );
        console.log("res", resp);
        data.response = true;
        data.path = resp.data.payload.slug;
        data.backend_data = resp.data.payload;
        callbackSaga(resp.data);
        // data.path = "/organisation/sow/" + resp.data.payload.slug; // Optionally update path as needed
      })
      .catch((error) => {
        if (error?.response?.data?.error) {
          toast.error(`${"Error"} : ${error?.response?.data?.error}`);
        }

        if (error?.response?.data?.start_date) {
          toast.error("Invalid Start date ,Please Select !");
        }

        if (error?.response?.data?.end_date) {
          toast.error("Invalid End date ,Please Select !");
        }
      });
  } else {
    yield axios
      .patch(
        API_URL + "sow/statement-of-work/" + id + "/",
        payload,
        AUTH_MULTYPART_HEADERS()
      )
      .then((resp) => {
        toast.success(
          "Statement Of Work for " +
            " " +
            resp.data.payload.title +
            " " +
            "update Successfully"
        );
        data.response = true;

        data.path = resp.data.payload.slug;

        // data.path = "/organisation/sow/" + resp.data.payload.slug
      })
      .catch((error) => {
        if (error?.response?.data?.error) {
          toast.error(`${"Error"} : ${error?.response?.data?.error}`);
        }

        if (error?.response?.data?.start_date) {
          toast.error("Invalid Start date ,Please Select !");
        }

        if (error?.response?.data?.end_date) {
          toast.error("Invalid End date ,Please Select !");
        }
      });
  }

  yield put({ type: action_type.GET_NAVIGATE, data });
}

export function* allOrgListSaga(payload) {
  yield put({ type: action_type.SET_LOADING, payload: true });

  let path;

  if (payload?.payload) {
    path =
      API_URL + `organisation/profile/?limit=${LIMIT_DATA}${payload?.payload}`;
  } else {
    path = API_URL + `organisation/profile/?limit=6`;
  }

  let data = { response: false, backend_data: [] };

  yield axios.get(path, AUTH_HEADERS()).then((resp) => {
    data.response = true;
    data.backend_data = resp.data;
  });

  yield put({ type: action_type.SET_ORGANISATION_LIST, data });
  yield put({ type: action_type.SET_LOADING, payload: false });
}

export function* OrgDetailsByIdSaga(payload) {
  try {
    yield put({ type: action_type.SET_LOADING, payload: true });

    const path = `${API_URL}organisation/profile/${payload?.payload}/`;
    const response = yield axios.get(path, AUTH_HEADERS());
    console.log("response", response);
    const data = {
      response: true,
      backend_data: response.data,
    };

    yield put({ type: action_type.SET_ORG_BY_ID, data });
  } catch (error) {
    console.error("Error fetching organization details:", error);
    const data = {
      response: false,
      backend_data: [],
    };
    yield put({ type: action_type.SET_ORG_BY_ID, data });
  } finally {
    yield put({ type: action_type.SET_LOADING, payload: false });
  }
}

export function* allMsaListSaga(payload) {
  let data = { response: false, backend_data: null };

  let url;

  // url = API_URL + `msa/master-agreement/?limit=${LIMIT_DATA}&${payload.payload}`

  if (payload?.payload?.url) {
    url = payload?.payload.url;
  } else {
    url =
      API_URL +
      `msa/master-agreement/?limit=${LIMIT_DATA}&${payload?.payload?.param}`;
  }

  yield axios.get(url, AUTH_HEADERS()).then((resp) => {
    data.response = true;
    data.backend_data = resp.data;

    if (resp.data.results) {
      for (let i of resp.data.results) {
        i.label = i.name;
      }
    }
  });

  yield put({ type: action_type.SET_MSA_LIST, data });
  yield put({ type: action_type.SET_LOADING, payload: false });
}

export function* allMsaFiltersListSaga(payload) {
  let data = { response: false, backend_data: null };

  let url;

  url =
    API_URL + `msa/master-agreement/?limit=${LIMIT_DATA}&${payload.payload}`;

  yield axios.get(url, AUTH_HEADERS()).then((resp) => {
    data.response = true;
    data.backend_data = resp.data;
  });

  yield put({ type: action_type.SET_MSA_FILTERS_LIST, data });
  yield put({ type: action_type.SET_LOADING, payload: false });
}

export function* msaDetailSaga({ slug }) {
  yield put({ type: action_type.SET_LOADING, payload: true });

  let data = { response: false, backend_data: null };

  let url = API_URL + `msa/master-agreement/${slug}/`;

  yield axios.get(url, AUTH_HEADERS()).then((resp) => {
    data.backend_data = resp?.data;
    data.response = true;
  });

  yield put({ type: action_type.SET_MSA_DETAIL, data });
  yield put({ type: action_type.SET_LOADING, payload: false });
}

export function* msaDynamicVariables({ payload }) {
  yield put({ type: action_type.SET_LOADING, payload: true });

  let data = { response: false, backend_data: null };

  let url = API_URL + `msa/agreement-template/dynamic_variables/?${payload}`;

  yield axios.get(url, AUTH_HEADERS()).then((resp) => {
    data.backend_data = resp?.data;
    data.response = true;
  });

  yield put({ type: action_type.SET_MSA_DYNAMIC_VARIABLES, data });
  yield put({ type: action_type.SET_LOADING, payload: false });
}
