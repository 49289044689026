import { put } from 'redux-saga/effects'
import * as action_type from '../../../../Store/Constant';
import { AUTH_MULTYPART_HEADERS, API_URL, AUTH_HEADERS, LIMIT_DATA, MULTYPART_HEADERS } from '../../../../Axios/axiosData'
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

export function* getSettingsCompanyList(payload) {

    let data = { response: false, backend_data: null }
    yield put({ type: action_type.SET_LOADING, payload: true })

    yield axios.get(API_URL + `settings/company-location/?limit=${LIMIT_DATA}${payload.payload}`, AUTH_MULTYPART_HEADERS())
        .then(resp => {
            data.response = true
            data.backend_data = resp.data

        }).catch((error) => {
            toast.error('Error');
        })

    yield put({ type: action_type.SET_LOADING, payload: false })
    yield put({ type: action_type.SET_SETTINGS_COMPANY_LIST, data })

}

export function* getBusinessUnitList(payload) {

    // let data = { response: false, backend_data: null }

    // let url;
    // if (payload?.url) {
    //     url = payload?.url
    // }
    // else {
    //     url = API_URL + `settings/business-unit/?limit=${LIMIT_DATA}`
    // }

    // yield axios.get(url, AUTH_HEADERS())
    // .then(resp => {
    //     data.response = true
    //     data.backend_data = resp.data

    // }).catch((error) => {
    //     console.log(error, "error")
    // })

    // yield put({ type: action_type.SET_BUSINESS_UNIT_LIST, data })
    // yield put({ type: action_type.SET_LOADING, payload: false })


    let data = { response: false, backend_data: null }
    yield put({ type: action_type.SET_LOADING, payload: true })

    yield axios.get(API_URL + `settings/business-unit/?limit=${LIMIT_DATA}${payload.payload}`, AUTH_MULTYPART_HEADERS())
        .then(resp => {
            data.response = true
            data.backend_data = resp.data

        }).catch((error) => {
            console.log(error, "error")
        })

    yield put({ type: action_type.SET_BUSINESS_UNIT_LIST, data })
    yield put({ type: action_type.SET_LOADING, payload: false })
}

export function* getCostCenterList(payload) {

    let data = { response: false, backend_data: null }
    yield put({ type: action_type.SET_LOADING, payload: true })

    yield axios.get(API_URL + `settings/cost-center/?limit=${LIMIT_DATA}${payload.payload}`, AUTH_MULTYPART_HEADERS())
        .then(resp => {
            data.response = true
            data.backend_data = resp.data
        }).catch((error) => {
            console.log(error, "error")
        })

    yield put({ type: action_type.SET_COST_CENTER_LIST, data })
    yield put({ type: action_type.SET_LOADING, payload: false })
}

export function* getUnpscCodeList(payload) {
    let data = { response: false, backend_data: null }
    yield put({ type: action_type.SET_LOADING, payload: true })

    yield axios.get(API_URL + `settings/unpsc-code/?limit=${LIMIT_DATA}${payload.payload}`, AUTH_MULTYPART_HEADERS())
        .then(resp => {
            data.response = true
            data.backend_data = resp.data
        }).catch((error) => {
            console.log(error, "error")
        })

    yield put({ type: action_type.SET_UNPSC_CODE_LIST, data })
    yield put({ type: action_type.SET_LOADING, payload: false })
}

export function* getTaxGroupList(payload) {

    let data = { response: false, backend_data: null }
    yield put({ type: action_type.SET_LOADING, payload: true })

    yield axios.get(API_URL + `settings/tax-group/?limit=${LIMIT_DATA}${payload.payload}`, AUTH_MULTYPART_HEADERS())
        .then(resp => {
            data.response = true
            data.backend_data = resp.data
        }).catch((error) => {
            console.log(error, "error")
        })

    yield put({ type: action_type.SET_TAX_GROUP_LIST, data })
    yield put({ type: action_type.SET_LOADING, payload: false })
}

export function* getExperienceCategoryList(payload) {

    let data = { response: false, backend_data: null }
    yield put({ type: action_type.SET_LOADING, payload: true })

    let url = ``

    if(payload?.payload){
        url = API_URL + `settings/expense-category/?limit=${LIMIT_DATA}${payload?.payload}`
    }else{
        url = API_URL + `settings/expense-category/?limit=${100}`
    }

    yield axios.get(url, AUTH_HEADERS())
        .then(resp => {
            data.response = true
            data.backend_data = resp.data
        }).catch((error) => {
            console.log(error, "error")
        })

    yield put({ type: action_type.SET_EXPERIENCE_CATEGORY_LIST, data })
    yield put({ type: action_type.SET_LOADING, payload: false })
}

export function* getServiceTaxList(payload) {

    let data = { response: false, backend_data: null }
    yield put({ type: action_type.SET_LOADING, payload: true })

    yield axios.get(API_URL + `settings/tax-service-type/?limit=${LIMIT_DATA}${payload.payload}`, AUTH_MULTYPART_HEADERS())
        .then(resp => {
            data.response = true
            data.backend_data = resp.data
        }).catch((error) => {
            console.log(error, "error")
        })

    yield put({ type: action_type.SET_TAX_SERVICE_LIST, data })
    yield put({ type: action_type.SET_LOADING, payload: false })
}

export function* getGlAccountList(payload) {

    let data = { response: false, backend_data: null }
    yield put({ type: action_type.SET_LOADING, payload: true })

    yield axios.get(API_URL + `settings/gl-account/?limit=${LIMIT_DATA}${payload.payload}`, AUTH_MULTYPART_HEADERS())
        .then(resp => {
            data.response = true
            data.backend_data = resp.data
        }).catch((error) => {
            console.log(error, "error")
        })

    yield put({ type: action_type.SET_GL_ACCOUNT_LIST, data })
    yield put({ type: action_type.SET_LOADING, payload: false })
}

export function* getPaymentTermList(payload) {

    let data = { response: false, backend_data: null }
    yield put({ type: action_type.SET_LOADING, payload: true })

    yield axios.get(API_URL + `settings/payment-terms/?limit=${LIMIT_DATA}${payload.payload}`, AUTH_MULTYPART_HEADERS())
        .then(resp => {
            data.response = true
            data.backend_data = resp.data
        }).catch((error) => {
            console.log(error, "error")
        })

    yield put({ type: action_type.SET_PAYMENT_TERM_LIST, data })
    yield put({ type: action_type.SET_LOADING, payload: false })
}

export function* getMsaTypeList(payload) {
    let data = { response: false, backend_data: null }
    yield put({ type: action_type.SET_LOADING, payload: true })

    yield axios.get(API_URL + `settings/msa-type/?limit=${LIMIT_DATA}${payload.payload}`, AUTH_MULTYPART_HEADERS())
        .then(resp => {
            data.response = true
            data.backend_data = resp.data
        }).catch((error) => {
        })

    yield put({ type: action_type.SET_MSA_TYPE_LIST, data })
    yield put({ type: action_type.SET_LOADING, payload: false })
}

export function* getSowTypeList(payload) {

    let data = { response: false, backend_data: null }
    yield put({ type: action_type.SET_LOADING, payload: true })

    yield axios.get(API_URL + `settings/sow-type/?limit=${LIMIT_DATA}${payload.payload}`, AUTH_MULTYPART_HEADERS())
        .then(resp => {
            data.response = true
            data.backend_data = resp.data
        }).catch((error) => {
            console.log(error, "error")
        })

    yield put({ type: action_type.SET_LOADING, payload: false })
    yield put({ type: action_type.SET_SOW_TYPE_LIST, data })
}

export function* getIncomeTaxSlabs(payload) {

    let data = { response: false, backend_data: null }
    yield put({ type: action_type.SET_LOADING, payload: true })

    yield axios.get(API_URL + `settings/tax-slabs/?limit=100${payload.payload}`, AUTH_MULTYPART_HEADERS())
        .then(resp => {
            data.response = true
            data.backend_data = resp.data
        }).catch((error) => {
            console.log(error, "error")
        })

    yield put({ type: action_type.SET_LOADING, payload: false })
    yield put({ type: action_type.SET_INCOME_TAX_SLABS_LIST, data })
}

export function* getPrivacySettingsList() {

    let data = { response: false, backend_data: null }
    yield put({ type: action_type.SET_LOADING, payload: true })

    yield axios.get(API_URL + `accounts/user-profile/privacy_settings/`, AUTH_MULTYPART_HEADERS())
        .then(resp => {
            data.response = true
            data.backend_data = resp.data
        }).catch((error) => {
            console.log(error, "error")
        })

    yield put({ type: action_type.SET_PRIVACY_SETTINGS_LIST, data })
    yield put({ type: action_type.SET_LOADING, payload: false })
}

export function* updatePrivacySetting({ payload }) {

    let data = { response: false, backend_data: null }
    let formValue = payload

    yield axios.patch(API_URL + `accounts/user-profile/privacy_settings/`, payload, AUTH_MULTYPART_HEADERS())
        .then(resp => {
            data.response = true
            data.backend_data = resp.data

            toast.success("Permissions/Data Privacy Updated")

        }).catch(err => {
            data.backend_data = err
            toast.error(err.message)
        })

        yield axios.get(API_URL + `accounts/user-profile/privacy_settings/`, AUTH_MULTYPART_HEADERS())
        .then(resp => {
            data.response = true
            data.backend_data = resp.data
        }).catch((error) => {
            console.log(error, "error")
        })

    yield put({ type: action_type.SET_PRIVACY_SETTINGS_LIST, data })
    yield put({ type: action_type.SET_LOADING, payload: false })
}




export function* getAddCompanyLocation({ payload }) {

    let data = { response: false, backend_data: null }
    let formValue = payload

    yield axios.post(API_URL + "settings/company-location/", formValue, AUTH_MULTYPART_HEADERS())
        .then(resp => {
            data.response = true
            data.backend_data = resp.data

            toast.success("Company Create Successfully")

        }).catch(err => {
            data.backend_data = err
            toast.error(err.message)
        })

    yield axios.get(API_URL + `settings/company-location/?limit=${LIMIT_DATA}`, AUTH_MULTYPART_HEADERS())
        .then(resp => {
            data.response = true
            data.backend_data = resp.data

        }).catch((error) => {
            console.log(error, "error")
        })

    yield put({ type: action_type.SET_SETTINGS_COMPANY_LIST, data })
    // yield put({ type: action_type.GET_NAVIGATE, data })
}

export function* getAddBusinessUnit({ payload }) {

    let data = { response: false, backend_data: null }
    let formDatas = payload

    yield axios.post(API_URL + "settings/business-unit/", formDatas, AUTH_MULTYPART_HEADERS())
        .then((resp) => {
            data.response = true
            data.backend_data = resp.data

            toast.success("Business Unit Create Successfully")

        }).catch((err) => {
            data.backend_data = err
            toast.error(err.message)
        })

    yield axios.get(API_URL + `settings/business-unit/?limit=${LIMIT_DATA}`, AUTH_MULTYPART_HEADERS())
        .then(resp => {
            data.response = true
            data.backend_data = resp.data
        }).catch((error) => {
            console.log(error, "error")
        })

    yield put({ type: action_type.SET_BUSINESS_UNIT_LIST, data })
    // yield put({ type: action_type.GET_NAVIGATE, data })
}

export function* getAddCostCenter({ payload }) {

    let data = { response: false, backend_data: null }
    let formValue = payload

    yield axios.post(API_URL + "settings/cost-center/", formValue, AUTH_MULTYPART_HEADERS())
        .then(resp => {
            data.response = true
            data.backend_data = resp.data

            toast.success("Cost-Center Create Successfully")

        }).catch(err => {
            data.backend_data = err
            toast.error(err.message)
        })

    yield axios.get(API_URL + `settings/cost-center/?limit=${LIMIT_DATA}`, AUTH_MULTYPART_HEADERS())
        .then(resp => {
            data.response = true
            data.backend_data = resp.data
        }).catch((error) => {
            console.log(error, "error")
        })

    yield put({ type: action_type.SET_COST_CENTER_LIST, data })
    // yield put({ type: action_type.GET_NAVIGATE, data })
}

export function* getAddGlAccounts({ payload }) {

    let data = { response: false, backend_data: [] }
    let formValue = payload

    yield axios.post(API_URL + "settings/gl-account/", formValue, AUTH_MULTYPART_HEADERS())
        .then(resp => {
            data.response = true
            data.backend_data = resp.data

            toast.success("Gl Accounts Create Successfully")

        }).catch(err => {
            data.backend_data = err
            toast.error(err.message)
        })

    yield axios.get(API_URL + `settings/gl-account/?limit=${LIMIT_DATA}`, AUTH_MULTYPART_HEADERS())
        .then(resp => {
            data.response = true
            data.backend_data = resp.data
        }).catch((error) => {
            console.log(error, "error")
        })

    yield put({ type: action_type.SET_GL_ACCOUNT_LIST, data })
    // yield put({ type: action_type.GET_NAVIGATE, data })
}

export function* getAddMsaType({ payload }) {

    let data = { response: false, backend_data: null }
    let formValue = payload

    yield axios.post(API_URL + "settings/msa-type/", formValue, AUTH_MULTYPART_HEADERS())
        .then(resp => {
            data.response = true
            data.backend_data = resp.data

            toast.success("MSA Type Create Successfully")

        }).catch(err => {
            data.backend_data = err
            toast.error(err.message)
        })

    yield axios.get(API_URL + `settings/msa-type/?limit=${LIMIT_DATA}`, AUTH_MULTYPART_HEADERS())
        .then(resp => {
            data.response = true
            data.backend_data = resp.data
        }).catch((error) => {
            console.log(error, "error")
        })

    yield put({ type: action_type.SET_MSA_TYPE_LIST, data })
    // yield put({ type: action_type.GET_NAVIGATE, data })
}

export function* getAddUnpscCode({ payload }) {

    let data = { response: false, backend_data: null }
    let formValue = payload

    yield axios.post(API_URL + "settings/unpsc-code/", formValue, AUTH_MULTYPART_HEADERS())
        .then(resp => {
            data.response = true
            data.backend_data = resp.data

            toast.success("UNSPSC Code Create Successfully")

        }).catch(err => {
            data.backend_data = err
            toast.error(err.message)
        })

    yield axios.get(API_URL + `settings/unpsc-code/?limit=${LIMIT_DATA}`, AUTH_MULTYPART_HEADERS())
        .then(resp => {
            data.response = true
            data.backend_data = resp.data
        }).catch((error) => {
            console.log(error, "error")
        })

    yield put({ type: action_type.SET_UNPSC_CODE_LIST, data })
    // yield put({ type: action_type.GET_NAVIGATE, data })
}

export function* getAddTaxGroup({ payload }) {

    let data = { response: false, backend_data: null }
    let formValue = payload

    yield axios.post(API_URL + "settings/tax-group/", formValue, AUTH_MULTYPART_HEADERS())
        .then(resp => {
            data.response = true
            data.backend_data = resp.data

            toast.success("Tax Group Create Successfully")

        }).catch(err => {
            data.backend_data = err
            toast.error(err.message)
        })

    yield axios.get(API_URL + `settings/tax-group/?limit=${LIMIT_DATA}`, AUTH_MULTYPART_HEADERS())
        .then(resp => {
            data.response = true
            data.backend_data = resp.data
        }).catch((error) => {
            console.log(error, "error")
        })

    yield put({ type: action_type.SET_TAX_GROUP_LIST, data })
    // yield put({ type: action_type.GET_NAVIGATE, data })
}

export function* getAddExpenseCategory({ payload }) {

    let data = { response: false, backend_data: null }
    let formValue = payload

    yield axios.post(API_URL + "settings/expense-category/", formValue, AUTH_HEADERS())
        .then(resp => {
            data.response = true
            data.backend_data = resp.data

            toast.success("Category Created Successfully")

        }).catch(err => {
            data.backend_data = err
            toast.error(err.message)
        })

    yield axios.get(API_URL + `settings/expense-category/?limit=${LIMIT_DATA}`, AUTH_HEADERS())
        .then(resp => {
            data.response = true
            data.backend_data = resp.data
        }).catch((error) => {
            console.log(error, "error")
        })

    yield put({ type: action_type.SET_EXPERIENCE_CATEGORY_LIST, data })
    yield put({ type: action_type.SET_LOADING, payload: false })
}



export function* getAddTaxService({ payload }) {

    let data = { response: false, backend_data: null }
    let formValue = payload

    yield axios.post(API_URL + "settings/tax-service-type/", formValue, AUTH_MULTYPART_HEADERS())
        .then(resp => {
            data.response = true
            data.backend_data = resp.data

            toast.success("Tax Rate Create Successfully")

        }).catch(err => {
            data.backend_data = err
            toast.error(err.message)
        })

    yield axios.get(API_URL + `settings/tax-service-type/?limit=${LIMIT_DATA}`, AUTH_MULTYPART_HEADERS())
        .then(resp => {
            data.response = true
            data.backend_data = resp.data
        }).catch((error) => {
            console.log(error, "error")
        })

    yield put({ type: action_type.SET_TAX_SERVICE_LIST, data })
    // yield put({ type: action_type.GET_NAVIGATE, data })
}

export function* getAddPaymentTerm({ payload }) {

    let data = { response: false, backend_data: null }
    let formValue = payload

    yield axios.post(API_URL + "settings/payment-terms/", formValue, AUTH_MULTYPART_HEADERS())
        .then(resp => {
            data.response = true
            data.backend_data = resp.data

            toast.success("Payment Terms Create Successfully")

        }).catch(err => {
            data.backend_data = err
            toast.error(err.message)
        })

    yield axios.get(API_URL + `settings/payment-terms/?limit=${LIMIT_DATA}`, AUTH_MULTYPART_HEADERS())
        .then(resp => {
            data.response = true
            data.backend_data = resp.data
        }).catch((error) => {
            console.log(error, "error")
        })

    yield put({ type: action_type.SET_PAYMENT_TERM_LIST, data })
    // yield put({ type: action_type.GET_NAVIGATE, data })
}

export function* getAddSowType({ payload }) {

    let data = { response: false, backend_data: null }
    let formValue = payload

    yield axios.post(API_URL + "settings/sow-type/", formValue, AUTH_MULTYPART_HEADERS())
        .then(resp => {
            data.response = true
            data.backend_data = resp.data

            toast.success("SOW Type Create Successfully")

        }).catch(err => {
            data.backend_data = err
            toast.error(err.message)
        })

    yield axios.get(API_URL + `settings/sow-type/?limit=${LIMIT_DATA}`, AUTH_MULTYPART_HEADERS())
        .then(resp => {
            data.response = true
            data.backend_data = resp.data
        }).catch((error) => {
            console.log(error, "error")
        })

    yield put({ type: action_type.SET_SOW_TYPE_LIST, data })
    // yield put({ type: action_type.GET_NAVIGATE, data })
}

export function* editUnpscCodeData({ payload }) {

    let data = { response: false, backend_data: null }

    yield axios.patch(API_URL + `settings/unpsc-code/${payload.slug}/`, payload.name, AUTH_HEADERS())
        .then(resp => {
            data.response = true
            data.backend_data = resp.data

            toast.success("Successfully Update")

        }).catch(err => {
            toast.error("Backend Error")
        })

    yield axios.get(API_URL + `settings/unpsc-code/?limit=${LIMIT_DATA}`, AUTH_MULTYPART_HEADERS())
        .then(resp => {
            data.response = true
            data.backend_data = resp.data
        }).catch((error) => {
            console.log(error, "error")
        })

    yield put({ type: action_type.SET_UNPSC_CODE_LIST, data })
}

export function* editTaxGroupData({ payload }) {

    let data = { response: false, backend_data: null }

    yield axios.patch(API_URL + `settings/tax-group/${payload.slug}/`, payload.form, AUTH_HEADERS())
        .then(resp => {
            data.response = true
            data.backend_data = resp.data

            toast.success("Successfully Update")

        }).catch(err => {
            toast.error("Backend Error")
        })

    yield axios.get(API_URL + `settings/tax-group/?limit=${LIMIT_DATA}`, AUTH_MULTYPART_HEADERS())
        .then(resp => {
            data.response = true
            data.backend_data = resp.data
        }).catch((error) => {
            console.log(error, "error")
        })

    yield put({ type: action_type.SET_TAX_GROUP_LIST, data })
}

export function* editExpenseCategory({ payload }) {

    let data = { response: false, backend_data: null }

    yield axios.patch(API_URL + `settings/expense-category/${payload.slug}/`, payload.form, AUTH_HEADERS())
        .then(resp => {
            data.response = true
            data.backend_data = resp.data

            toast.success("Successfully Update")

        }).catch((error) => {

            if (error?.response?.data?.message) {
                toast.error(`${error?.response?.data?.message}`)
            }

        })

    yield axios.get(API_URL + `settings/expense-category/?limit=${LIMIT_DATA}`, AUTH_HEADERS())
        .then(resp => {
            data.response = true
            data.backend_data = resp.data
        }).catch((error) => {
            console.log(error, "error")
        })

    yield put({ type: action_type.SET_EXPERIENCE_CATEGORY_LIST, data })
    yield put({ type: action_type.SET_LOADING, payload: false })
}



export function* editTaxServiceRateData({ payload }) {

    let data = { response: false, backend_data: null }

    yield axios.patch(API_URL + `settings/tax-service-type/${payload.slug}/`, payload.form, AUTH_HEADERS())
        .then(resp => {
            data.response = true
            data.backend_data = resp.data

            toast.success("Successfully Update")

        }).catch((error) => {

            toast.error(`${error.response.data.message}`)
        })

    yield axios.get(API_URL + `settings/tax-service-type/?limit=${LIMIT_DATA}`, AUTH_MULTYPART_HEADERS())
        .then(resp => {
            data.response = true
            data.backend_data = resp.data
        }).catch((error) => {
            console.log(error, "error")
        })

    yield put({ type: action_type.SET_TAX_SERVICE_LIST, data })
}

export function* editCostCenterData({ payload }) {

    let data = { response: false, backend_data: null }

    yield axios.patch(API_URL + `settings/cost-center/${payload.slug}/`, payload.form, AUTH_HEADERS())
        .then(resp => {
            data.response = true
            data.backend_data = resp.data

            toast.success("Successfully Update")

        }).catch(err => {
            toast.error("Backend Error")
        })

    yield axios.get(API_URL + `settings/cost-center/?limit=${LIMIT_DATA}`, AUTH_MULTYPART_HEADERS())
        .then(resp => {
            data.response = true
            data.backend_data = resp.data
        }).catch((error) => {
            console.log(error, "error")
        })

    yield put({ type: action_type.SET_COST_CENTER_LIST, data })
}

export function* editGlAccountsData({ payload }) {

    let data = { response: false, backend_data: null }

    yield axios.patch(API_URL + `settings/gl-account/${payload.slug}/`, payload.form, AUTH_HEADERS())
        .then(resp => {
            data.response = true
            data.backend_data = resp.data

            toast.success("Successfully Update")

        }).catch(err => {
            toast.error("Backend Error")
        })

    yield axios.get(API_URL + `settings/gl-account/?limit=${LIMIT_DATA}`, AUTH_MULTYPART_HEADERS())
        .then(resp => {
            data.response = true
            data.backend_data = resp.data
        }).catch((error) => {
            console.log(error, "error")
        })

    yield put({ type: action_type.SET_GL_ACCOUNT_LIST, data })
}


export function* editPaymentTermData({ payload }) {

    let data = { response: false, backend_data: null }

    yield axios.patch(API_URL + `settings/payment-terms/${payload.slug}/`, payload.form, AUTH_HEADERS())
        .then(resp => {
            data.response = true
            data.backend_data = resp.data

            toast.success("Successfully Update")

        }).catch(err => {
            toast.error("Backend Error")
        })

    yield axios.get(API_URL + `settings/payment-terms/?limit=${LIMIT_DATA}`, AUTH_MULTYPART_HEADERS())
        .then(resp => {
            data.response = true
            data.backend_data = resp.data
        }).catch((error) => {
            console.log(error, "error")
        })

    yield put({ type: action_type.SET_PAYMENT_TERM_LIST, data })
}

export function* editMsaTypeData({ payload }) {

    let data = { response: false, backend_data: null }

    yield axios.patch(API_URL + `settings/msa-type/${payload.slug}/`, payload.form, AUTH_HEADERS())
        .then(resp => {
            data.response = true
            data.backend_data = resp.data

            toast.success("Successfully Update")

        }).catch(err => {
            toast.error("Backend Error")
        })

    yield axios.get(API_URL + `settings/msa-type/?limit=${LIMIT_DATA}`, AUTH_MULTYPART_HEADERS())
        .then(resp => {
            data.response = true
            data.backend_data = resp.data
        }).catch((error) => {
            toast.error("Backend Error")
        })

    yield put({ type: action_type.SET_MSA_TYPE_LIST, data })
}

export function* editSowTypeData({ payload }) {

    let data = { response: false, backend_data: null }

    yield axios.patch(API_URL + `settings/sow-type/${payload.slug}/`, payload.form, AUTH_HEADERS())
        .then(resp => {
            data.response = true
            data.backend_data = resp.data

            toast.success("Successfully Update")

        }).catch(err => {
            toast.error("Backend Error")
        })

    yield axios.get(API_URL + `settings/sow-type/?limit=${LIMIT_DATA}`, AUTH_MULTYPART_HEADERS())
        .then(resp => {
            data.response = true
            data.backend_data = resp.data
        }).catch((error) => {
            console.log(error, "error")
        })

    yield put({ type: action_type.SET_SOW_TYPE_LIST, data })
}

export function* editBusinessUnitData({ payload }) {

    let data = { response: false, backend_data: null }

    yield axios.patch(API_URL + `settings/business-unit/${payload.slug}/`, payload.form, AUTH_HEADERS())
        .then(resp => {
            data.response = true
            data.backend_data = resp.data

            toast.success("Successfully Update")

        }).catch((error) => {

            toast.error(`${error.response.data.message}`)

        })

    yield axios.get(API_URL + `settings/business-unit/?limit=${LIMIT_DATA}`, AUTH_MULTYPART_HEADERS())
        .then(resp => {
            data.response = true
            data.backend_data = resp.data
        }).catch((error) => {
            console.log(error, "error")
        })

    yield put({ type: action_type.SET_BUSINESS_UNIT_LIST, data })
}

export function* editCompanyLocationData({ payload }) {

    let data = { response: false, backend_data: null }

    yield axios.patch(API_URL + `settings/company-location/${payload.slug}/`, payload.form, AUTH_HEADERS())
        .then(resp => {
            data.response = true
            data.backend_data = resp.data

            toast.success("Successfully Update")

        }).catch((error) => {

            toast.error(`${error.response.data.message}`)

        })

    yield axios.get(API_URL + `settings/company-location/?limit=${LIMIT_DATA}`, AUTH_MULTYPART_HEADERS())
        .then(resp => {
            data.response = true
            data.backend_data = resp.data

        }).catch((error) => {
            console.log(error, "error")
        })

    yield put({ type: action_type.SET_SETTINGS_COMPANY_LIST, data })
}

export function* deleteExpenseCategoryData({ payload }) {

    let data = { response: false, backend_data: null }

    yield axios.patch(API_URL + `settings/expense-category/${payload}/`, AUTH_HEADERS())
        .then(resp => {
            data.response = true
            data.backend_data = resp.data

            toast.success("Category Delete Successfully")

        }).catch((error) => {

            if (error?.response?.data?.message) {
                toast.error(`${error?.response?.data?.message}`)
            }

        })

    yield axios.get(API_URL + `settings/expense-category/?limit=${LIMIT_DATA}`, AUTH_HEADERS())
        .then(resp => {
            data.response = true
            data.backend_data = resp.data
        }).catch((error) => {
            console.log(error, "error")
        })

    yield put({ type: action_type.SET_EXPERIENCE_CATEGORY_LIST, data })
    yield put({ type: action_type.SET_LOADING, payload: false })
}