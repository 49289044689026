import { put } from "redux-saga/effects";
import * as action_type from "../../../../Store/Constant";
import {
  AUTH_MULTYPART_HEADERS,
  API_URL,
  AUTH_HEADERS,
  LIMIT_DATA,
} from "../../../../Axios/axiosData";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

function dummy_role() {
  let user = JSON.parse(localStorage.getItem("user_data"));

  let data = {
    organisation: "",
    role_name: "",
    permissions: {
      msa: {
        add: false,
        change: false,
        delete: false,
        view: false,
        approval: false,
      },
      sow: {
        add: false,
        change: false,
        delete: false,
        view: false,
        approval: false,
      },
      timesheet: {
        add: false,
        change: false,
        delete: false,
        view: false,
        approval: false,
      },
      // attendance: {
      //     add: false,
      //     change: false,
      //     delete: false,
      //     view: false,
      //     approval: false
      // },
      business_unit: {
        add: false,
        change: false,
        delete: false,
        view: false,
      },
      company_location: {
        add: false,
        change: false,
        delete: false,
        view: false,
      },
      cost_center: {
        add: false,
        change: false,
        delete: false,
        view: false,
      },
      gl_account: {
        add: false,
        change: false,
        delete: false,
        view: false,
      },
      sow_type: {
        add: false,
        change: false,
        delete: false,
        view: false,
      },
      sow_tax_group: {
        add: false,
        change: false,
        delete: false,
        view: false,
      },
      unspsc_code: {
        add: false,
        change: false,
        delete: false,
        view: false,
      },
      sow_tax_center: {
        add: false,
        change: false,
        delete: false,
        view: false,
      },
      sow_payment_terms: {
        add: false,
        change: false,
        delete: false,
        view: false,
      },
      invoice: {
        add: false,
        change: false,
        delete: false,
        view: false,
      },
      // timesheet: {
      //     add: false,
      //     change: false,
      //     delete: false,
      //     view: false,
      //     approval: false
      // },
      agency: {
        add: false,
        change: false,
        delete: false,
        view: false,
      },
      resource: {
        add: false,
        change: false,
        delete: false,
        view: false,
      },
      payment: {
        add: false,
        change: false,
        delete: false,
        view: false,
      },
    },
  };

  if (user?.user_type === "ORGANISATION_USER") {
    data["organisation"] = user?.profile?.id;
  }
  if (user?.user_type === "AGENCY_USER") {
    data["agency"] = user?.profile?.id;
  }

  return data;
}

// export function* orgRoleListSage({ path }) {
export function* orgRoleListSage(payload) {
  yield put({ type: action_type.SET_LOADING, payload: true });

  let path = "";

  if (payload?.payload) {
    path = API_URL + `accounts/role/?limit=${LIMIT_DATA}${payload?.payload}`;
  } else {
    path = API_URL + "accounts/role/?limit=500";
  }

  // if (!path) {
  //     path = API_URL + 'accounts/role/?limit=100'
  // }

  // let data = { response: false, backend_data: [] }
  // yield axios.get(path, AUTH_HEADERS())

  let data = { response: false, backend_data: [] };

  yield axios
    .get(path, AUTH_HEADERS())
    .then((resp) => {
      data.response = true;
      data.backend_data = resp.data;
    })
    .catch((err) => {
      console.log("error", err);
    });

  yield put({ type: action_type.SET_ORG_ROLE_LIST, data });
  yield put({ type: action_type.SET_LOADING, payload: false });
}

export function* orgRoleDetailSaga({ id }) {
  yield put({ type: action_type.SET_LOADING, payload: true });
  let data = { role: {} };

  if (id == "add") {
    data["role"] = dummy_role();
  } else {
    let url = API_URL + "accounts/role/" + id + "/";
    yield axios
      .get(url, AUTH_HEADERS())
      .then((resp) => {
        data.response = true;
        data.role = resp.data;
      })
      .catch((err) => {
        alert(err.message);
      });
  }

  yield put({ type: action_type.SET_LOADING, payload: false });
  yield put({ type: action_type.SET_ORG_ROLE_DETAIL, data });
}

export function* editOrgRoleSaga({ payload }) {
  yield put({ type: action_type.SET_LOADING, payload: true });
  let data = { response: false, backend_data: null };
 
  if (payload?.delete) {

    yield axios
      .delete(API_URL + "accounts/role/" + payload?.id + "/", AUTH_HEADERS())
      .then((resp) => {
        // data.path = "/organisation/role/"
        data.response = true;
        payload.onCallBack(resp)

      })
      .catch((err) => {
        if (err.response.data.role_name) {
          toast.error("Role Name " + err.response.data.role_name[0]);
        } else {
          toast.error(err.message);
        }
      });


  } else {
    let new_form = payload.form_data;
    new_form["updated_permissions"] = new_form.permissions;

    if (payload?.id == "add") {
      yield axios
        .post(API_URL + "accounts/role/", new_form, AUTH_HEADERS())
        .then((resp) => {
          // data.path = "/organisation/role/"
          data.response = true;

          toast.success("Role Added successfully");
        })
        .catch((err) => {
          if (err.response.data.role_name) {
            toast.error("Role Name " + err.response.data.role_name[0]);
          } else {
            toast.error(err.message);
          }
        });
    } else {
      yield axios
        .put(
          API_URL + "accounts/role/" + payload.id + "/",
          new_form,
          AUTH_HEADERS()
        )
        .then((resp) => {
          // data.path = "/organisation/role/"
          data.response = true;

          toast.success("Role Edited Successfully");
        })
        .catch((err) => {
          if (err.response.data.role_name) {
            toast.error("Role Name " + err.response.data.role_name[0]);
          } else {
            toast.error(err.message);
          }
        });
    }
  }


  yield put({ type: action_type.GET_NAVIGATE, data });
  yield put({ type: action_type.SET_LOADING, payload: false });
}
