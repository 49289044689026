import { put } from 'redux-saga/effects'
import * as action_type from '../../../../Store/Constant';
import { AUTH_MULTYPART_HEADERS, API_URL } from '../../../../Axios/axiosData'
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';


export function* agencyProfileSaga({ payload }) {

    yield axios.post(API_URL + 'agency/agency-profile/', payload, AUTH_MULTYPART_HEADERS()).then(resp => {

        if (resp.data.status < 205) {
            let user_data = JSON.parse(localStorage.getItem("user_data"))
            user_data.profile = resp.data.profile
            localStorage.setItem("user_data", JSON.stringify(user_data))
            window.location.reload('.')
        }
        else {
            toast.error(`Failed to submit form with status code ${resp.data.status}`)
        }
    })
        .catch((error) => {
            if (error?.response?.data?.company_website) {
                toast.error(`${"Website"} : ${error?.response?.data?.company_website[0]}`)
            }

            if (error?.response?.data?.company_name) {
                toast.error(`${error?.response?.data?.company_name[0]}`)
            }

            setTimeout(() => {
                window.location.reload('.')
            }, 4500);
        })

    yield put({ type: action_type.SET_LOADING, payload: false })
}

export function* lawyerProfileSaga({ payload }) {

    yield axios.post(API_URL + 'lawyer-network/profile/', payload, AUTH_MULTYPART_HEADERS()).then(resp => {
        
        if (resp.data.status < 205) {
            let user_data = JSON.parse(localStorage.getItem("user_data"))
            user_data.profile = resp.data.profile
            localStorage.setItem("user_data", JSON.stringify(user_data))
            window.location.reload('.')
        }
        else {
            toast.error(`Failed to submit form with status code ${resp.data.status}`)
        }
    })
        .catch((error) => {
            if (error?.response?.data?.company_website) {
                toast.error(`${"Website"} : ${error?.response?.data?.company_website[0]}`)
            }

            if (error?.response?.data?.company_name) {
                toast.error(`${error?.response?.data?.company_name[0]}`)
            }

            setTimeout(() => {
                window.location.reload('.')
            }, 4500);
        })

    yield put({ type: action_type.SET_LOADING, payload: false })
}

