import * as action_type from '../../../../Store/Constant'

export const forgotSendEmailReducer = (data = {response: false, backend_data: null }, action) => {
    switch (action.type) {
        case action_type.SET_FORGOT_SEND_EMAIL:
            return action.data;
        default:
            return data
    }
}

export const forgotVerifyOTPReducer = (data = {response: false, backend_data: null }, action) => {
    switch (action.type) {
        case action_type.SET_VERITY_FORGOT_OTP:
            return action.data;
        default:
            return data
    }
}