import * as action_type from '../../../../Store/Constant'

export const msaListExcelReducer = (data = {response: false, backend_data: null }, action) => {
    switch (action.type) {
        case action_type.SET_MSA_DATA_EXCEL:
            return action.data;
        default:
            return data
    }
}

export const sowListExcelReducer = (data = {response: false, backend_data: null }, action) => {
    switch (action.type) {
        case action_type.SET_SOW_DATA_EXCEL:
            return action.data;
        default:
            return data
    }
}

export const timesheetListExcelReducer = (data = {response: false, backend_data: null }, action) => {
    switch (action.type) {
        case action_type.SET_TIMESHEET_DATA_EXCEL:
            return action.data;
        default:
            return data
    }
}

export const invoiceListExcelReducer = (data = {response: false, backend_data: null }, action) => {
    switch (action.type) {
        case action_type.SET_INVOICE_DATA_EXCEL:
            return action.data;
        default:
            return data
    }
}

export const roleListExcelReducer = (data = {response: false, backend_data: null }, action) => {
    switch (action.type) {
        case action_type.SET_ROLE_DATA_EXCEL:
            return action.data;
        default:
            return data
    }
}

export const usersListExcelReducer = (data = {response: false, backend_data: null }, action) => {
    switch (action.type) {
        case action_type.SET_USERS_DATA_EXCEL:
            return action.data;
        default:
            return data
    }
}