import * as action_type from '../../../../Store/Constant'

export const orgDashboardDataReducer = (data = {response: false, backend_data: null }, action) => {
    switch (action.type) {
        case action_type.SET_ORG_DASHBOARD_DATA:
            return action.data;
        default:
            return data
    }
}

export const agnDashboardDataReducer = (data = {response: false, backend_data: null }, action) => {
    switch (action.type) {
        case action_type.SET_AGN_DASHBOARD_DATA:
            return action.data;
        default:
            return data
    }
}

export const resDashboardDataReducer = (data = {response: false, backend_data: null }, action) => {
    switch (action.type) {
        case action_type.SET_RES_DASHBOARD_DATA:
            return action.data;
        default:
            return data
    }
}

export const expiringSowMsaDataReducer = (data = {response: false, backend_data: null }, action) => {
    switch (action.type) {
        case action_type.SET_EXPIRING_SOW_MSA_DATA:
            return action.data;
        default:
            return data
    }
}

export const revenueExpenseDataReducer = (data = {response: false, backend_data: null }, action) => {
    switch (action.type) {
        case action_type.SET_REVENUE_EXPENSCE_DATA:
            return action.data;
        default:
            return data
    }
}

export const agencyStatisticDataReducer = (data = {response: false, backend_data: null }, action) => {
    switch (action.type) {
        case action_type.SET_STATISTICS_DASHBOARD_DATA:
            return action.data;
        default:
            return data
    }
}

export const totalSpendDataReducer = (data = {response: false, backend_data: null }, action) => {
    switch (action.type) {
        case action_type.SET_TOTAL_SPEND_DATA:
            return action.data;
        default:
            return data
    }
}

export const resourceEarningsDataReducer = (data = {response: false, backend_data: null }, action) => {
    switch (action.type) {
        case action_type.SET_RESOURCE_TOTAL_EARNINGS_DATA:
            return action.data;
        default:
            return data
    }
}